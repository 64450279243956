import React from "react";

// Customizable Area Start
import {
  Box, Typography,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import AuthComp from "./AuthComp.web";
import { IoEyeOutline, IoEyeOffOutline, IoCloseCircleOutline, IoCheckmarkCircleOutline } from "react-icons/io5";
import { MdOutlineCircle } from "react-icons/md";
import { thumbPrintImg } from "./assets";
import "./styles/form.css";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class CreatePassword extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 renderIcon = (isValid: boolean | null) => {
    if (isValid === null) {
      return <MdOutlineCircle color={'#ffffff'} size={21} className="circleIcon" data-testid="closeIcon"/>;
    }
    return isValid ? <IoCheckmarkCircleOutline color={'#72efdd'} size={21} data-testid="checkIcon" className="checkIcon" /> : <IoCloseCircleOutline color={'#f87171'} size={21} className="closeIcon" data-testid="dashIcon"  />;
  }

  renderPasswordVisibleType = () => {
    return this.state.passwordVisible ? "text" : "password";
  }
  
  renderRePasswordVisibleType = () => {
    return this.state.repasswordVisible ? "text" : "password";
  }

  renderPasswordVisibilityIcon = () => {
    return this.state.passwordVisible ? <IoEyeOffOutline className="eyeIcon" data-testid="passwordInvisible" /> : <IoEyeOutline className="eyeIcon" data-testid="passwordVisible" />};

  renderRePasswordVisibilityIcon = () => {
    return this.state.repasswordVisible ? <IoEyeOffOutline className="eyeIcon" data-testid="repasswordInvisible" /> : <IoEyeOutline className="eyeIcon" data-testid="repasswordVisible" />
  }

  renderClassname = (passwordReq: any) => {
    if (passwordReq === null) return 'validationText' 
    return passwordReq ? 'isValid': 'notValid';
  }

 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <AuthComp
        navigation={undefined}
        id={""}
        factsBgImg={thumbPrintImg}
        factsHeading={this.factsHeadingPassword}
        factsInfoText={this.factsInfoTextPassword}
        factsInfoText2={this.factsInfoText2Password}
        factsIcon={<span aria-label="lock">&#x1f512;</span>}
        backBtn={true}
        goBack={this.goBack}
        heading1={this.heading1Password}
        heading3={this.heading3Password}
        heading1style={"headingTransform"}
        heading3style={"headingTransform"}
        popupError={this.state.error}
        warning={this.state.warning}
        onclick={this.createPassword}
        form={
          <>
            <Box className="inputWrapper">
              <input
                type={this.renderPasswordVisibleType()}
                id="password"
                name="password"
                value={this.state.passwordCache}
                onChange={(e) => this.handlePasswordChange(e)}
                className="input"
                data-testid="password"
                maxLength={16}
                autoComplete="new-password"
                required />
              <label htmlFor="password" className="label" data-testid="labelPassword">{this.labelPassword}</label>

              <span onClick={this.showHidePassword} className="passwordIconWrapper" data-testid="passwordVisibleWrapper">{this.renderPasswordVisibilityIcon()}</span>
            </Box>

            <Box className="inputWrapper">
              <input
                type={this.renderRePasswordVisibleType()}
                id="reEnterPassword"
                name="reEnterPassword"
                value={this.state.reTypePassword}
                onChange={(e) => this.handleConfirmPasswordChange(e)}
                className="input"
                data-testid="rePassword"
                maxLength={16}
                autoComplete="new-password"
                required />
              <label className="label" htmlFor="reEnterPassword" data-testid="labelRepassword" >{this.labelRePassword}</label>
              <span onClick={this.showHideRepassword} className="passwordIconWrapper" data-testid="repasswordVisibleWrapper">{this.renderRePasswordVisibilityIcon()}</span>
            </Box>


            <Box className="validationContainer">
              <Box className="validationWrapper" data-testid="uppercase">
                {this.renderIcon(this.state.hasUppercase)}&nbsp;&nbsp;<Typography component="p" className={this.renderClassname(this.state.hasUppercase)}>{this.passwordUpperCaseValidation}</Typography>
              </Box>

              <Box className="validationWrapper" data-testid="lowercase">
                {this.renderIcon(this.state.hasLowerCase)}&nbsp;&nbsp;<Typography component="p" className={this.renderClassname(this.state.hasLowerCase)}>{this.passwordLowerCaseValidation}</Typography>
              </Box>

              <Box className="validationWrapper">
                {this.renderIcon(this.state.hasSpecialChar)}&nbsp;&nbsp;<Typography component="p" className={this.renderClassname(this.state.hasSpecialChar)} data-testid="specialChar">{this.passwordSpecialCharValidation}</Typography>
              </Box>

              <Box className="validationWrapper">
                {this.renderIcon(this.state.hasNumber)}&nbsp;&nbsp;<Typography component="p" className={this.renderClassname(this.state.hasNumber)} data-testid="number">{this.passwordNumberValidation}</Typography>
              </Box>

              <Box className="validationWrapper">
                {this.renderIcon(this.state.hasEightChar)}&nbsp;&nbsp;<Typography component="p" className={this.renderClassname(this.state.hasEightChar)} data-testid="length">{this.passwordLengthValidation}</Typography>
              </Box>
            </Box>
          </>
        } />
       // Customizable Area End
    );
  }
}

