import React from "react";

// Customizable Area Start
import {
  Typography,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import AuthComp from './AuthComp.web'
import './styles/authComp.css';
import "./styles/termsAndConditions.css";
import { IoChevronBack } from "react-icons/io5";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class TermsAndConditions extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <AuthComp
        navigation={undefined}
        id={""}
        backBtn={false}
        terms={
          <div className="terms-container">
            <div className="bgEllipsis" data-testid="bgEllipsis"></div>
            <div className="terms-wrapper">
              <div className="terms-header">
                <button
                  className="backBtn termsBtn"
                  data-testid="backBtn"
                  onClick={this.goBack}
                >
                  <IoChevronBack color="#ffffff" size={20} />
                </button>
                <Typography
                  variant="h1"
                  component="h1"
                  className="heading heading1 terms-heading"
                  data-testid="heading1"
                >
                  {this.labelLegalTermCondition}
                </Typography>
              </div>

              <div className="terms-content">
                {this.state.termsData.map((term: any, index: number) => (
                  <div key={index.toString()} className="term">
                    <h3
                      className="title"
                      data-testid={`termTitle-${term.attributes?.id}`}
                    >
                      {term.attributes?.title}
                    </h3>
                    <p
                      className="desc"
                      data-testid={`termDesc-${term.attributes?.id}`}
                    >
                      {term.attributes?.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
      />
      // Customizable Area End
    );
  }
}

