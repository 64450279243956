import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, skip_icon } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  btnVisible?: boolean;
  showMenu?: boolean;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedDiv: null | any;
  anchorElHome: boolean | null;
  anchorElTools: boolean | null;
  anchorElServices: boolean | null;
  anchorElhelp: boolean | null;
  cardDetails: any;
  medIDJourney: any;
  selectedMenu: number;
  menuItems: any;
  anchorEls: any;
  glowParams: any;
  addNewTask: boolean;
  createdTask: any;
  currentDate: any;
  showMessage: string;
  userName: string;
  medEdCardDetails: any;
  isQuestionModal: boolean;
  startNewQuestions: boolean;
  questions: any;
  courseStart: boolean;
  continueLearning: any;
  formattedDuration: string;
  mouseHover: boolean;
  currentGlow: string;
  selectedDate: number;
  currentPage: any;
  questionsPerPage: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class Catalogue1Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  newTaskApiCallID: any;
  categoryApiCallID: any;
  continueLearningApiCallID: any;
  recommendedCardApiCallID: any;
  medEdKnowledgeCardApiCallID: any;
  questionBankListApiCallID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedDiv: null,
      anchorElHome: null,
      anchorElTools: null,
      anchorElServices: null,
      anchorElhelp: null,
      selectedMenu: 1,
      cardDetails: [
        // {
        //   id:1,
        //   subTitle: 'Molecular Innovations',
        //   title: 'Metabolism',
        //   unit: '7 units',
        //   hour: '10h 30min',
        //   level: 'Level 1',
        //   bgImg: card6,
        //   desc: 'Explores the biochemical foundations of metabolism, regulatory mechanisms, and their implications for medical science.'
        // },
        // {
        //   id:2,
        //   subTitle: 'Experimental Sciences',
        //   title: 'Laboratory Techniques',
        //   unit: '5 units',
        //   hour: '5h',
        //   level: 'Level 1',
        //   bgImg: card5,
        //   desc: "Explores metabolism's biochemical basis,energy conversion and medical implications foundations of metabolism, regulatory mechanisms."
        // },
        // {
        //   id:3,
        //   subTitle: 'Molecular Innovations',
        //   title: 'Molecular',
        //   unit: '15 units',
        //   hour: '20h 15min',
        //   level: 'Level 2',
        //   bgImg: card4,
        //   desc: "Explores metabolism's biochemical basis, energy conversion and medical implications foundations of metabolism, regulatory mechanisms."
        // }
      ],
      medEdCardDetails: [],
      medIDJourney: [],
      menuItems: [
        { id: "home", label: "Home", items: ["My Schedule", "My Files"] },
        { id: "tools", label: "My Tools", items: ["My Tools1", "My Tools"] },
        {
          id: "services",
          label: "My Services",
          items: [
            "Knowledge & Training Center",
            "Healthcare VR/AR & Gaming",
            "Marketplace",
            "Healthcare Research",
            "Healthcare Innovation & Enterpreneurship",
          ],
        },
        { id: "help", label: "Help Center", items: ["Help1"] },
      ],
      anchorEls: {},
      glowParams: {
        color: "#72efdd",
        rotate: -1,
      },
      addNewTask: false,
      createdTask: [],
      currentDate: new Date(),
      showMessage: "",
      userName: "",
      isQuestionModal: false,
      startNewQuestions: false,
      questions: [],

      courseStart: true,
      continueLearning: [],
      formattedDuration: "",
      mouseHover: false,
      currentGlow: "",
      selectedDate: -1,
      currentPage: 0, // To track the current page
      questionsPerPage: 3, // Number of questions per page

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.newTaskApiCallID) {
        this.setState({
          createdTask: responseJson.data,
          showMessage: responseJson.message,
        });
      } else if (apiRequestCallId === this.categoryApiCallID) {
        this.setState({ medIDJourney: responseJson.data });
      } else if (apiRequestCallId === this.recommendedCardApiCallID) {
        this.setState({ cardDetails: responseJson.data });
      } else if (apiRequestCallId === this.medEdKnowledgeCardApiCallID) {
        this.setState({ medEdCardDetails: responseJson.data });
      } else if (apiRequestCallId === this.questionBankListApiCallID) {
        this.setState({ questions: responseJson.data });
      } else if (apiRequestCallId === this.continueLearningApiCallID) {
        this.setState({ continueLearning: responseJson.data });
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  componentDidMount = async () => {
    await this.onClickCategory();
    await this.onRecommendedCard();
    let usrName = await getStorageData("userName");
    this.setState({ userName: usrName });
    await this.onMedEdKnowledgeDetail();
    await this.listofQuestionBank();
    await this.onContinueLearning();
    await this.onNewTask();

    if ((this.props.id as any) === "3") {
      this.setState({ selectedMenu: 3 });
    }

    if ((this.props.id as any) === "2") {
      this.setState({ selectedMenu: 2 });
    }
  };

  goToTermsAndConditions = () => {
    this.props.navigation.navigate("TermsAndConditions");
  };

  handlePageChange = (pageIndex: any) => {
    this.setState({ currentPage: pageIndex });
  };

  handleScheduleClick = () => {
    this.props.navigation.navigate("Scheduling");
  };

  onMenuItemClick = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "KnowledgeTrainingCenter"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };
  onClickHome = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Catalogue1"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  onRecommendedCard = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.recommendedCardApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.recommendedCardEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onNewTask = async () => {
    this.setState({ addNewTask: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.newTaskApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.newTaskApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onClickCategory = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.categoryApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.medEdCardKnowledgeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onMedEdKnowledgeDetail = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.medEdKnowledgeCardApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.medEdCardKnowledgeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  listofQuestionBank = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.questionBankListApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.questionBankListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onContinueLearning = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("signupToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.continueLearningApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.continueLearningEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
