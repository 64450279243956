import React from "react";

// Customizable Area Start
import { Box, Typography, Checkbox } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import AuthComp from "../../email-account-registration/src/AuthComp.web";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <AuthComp
        navigation={undefined}
        id={""}
        // blackText={this.headingWelcome}
        colouredInfoText={`${this.appName}.`}
        onclick={this.handleEmailLogin}
        buttonText={this.nextBtnText}
        btnDisabled={this.state.btnDisabled}
        // img={false}
        // a={true}
        // navBorder={true}
        // login={true}
        // error={this.state.error}
        // goToLogin={this.goToLogin}
        // goToSignup={this.goToSignUp}
        form={
          <Box style={{ width: "100%" }}>
            <Box style={{ marginTop: "10px" }}>
              <label
                htmlFor="email"
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "30px",
                  fontFamily: "'Inter', sans-serif",
                  color: "#334155",
                }}
                data-testid="emailLabel"
              >
                {this.state.placeHolderEmail}
              </label>
              <input
                type="text"
                id="email"
                name="email"
                value={this.state.email}
                placeholder={this.state.placeHolderEmail}
                onChange={(e) => this.handleEmailChange(e)}
                style={{
                  width: "100%",
                  display: "block",
                  border: "1px solid #CBD5E1",
                  borderRadius: "8px",
                  padding: "10px",
                  fontSize: "16px",
                  outline: "none",
                }}
                data-testid="email"
              />
            </Box>

            <Box style={{ marginTop: "10px" }}>
              <label
                htmlFor="password"
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "30px",
                  fontFamily: "'Inter', sans-serif",
                  color: "#334155",
                }}
                data-testid="passwordLabel"
              >
                {this.placeholderPassword}
              </label>
              <Box style={{ display: "flex", position: "relative" }}>
                <input
                  type={this.state.enablePasswordField ? "text" : "password"}
                  id="password"
                  name="password"
                  value={this.state.password}
                  placeholder={this.placeholderPassword}
                  onChange={(e) => this.handlePasswordChange(e)}
                  style={{
                    width: "100%",
                    display: "block",
                    border: "1px solid #CBD5E1",
                    borderRadius: "8px",
                    padding: "10px",
                    fontSize: "16px",
                    outline: "none",
                  }}
                  data-testid="password"
                />
                <span
                  onClick={this.handleClickShowPassword}
                  style={{ position: "absolute", top: "8px", right: "10px" }}
                  data-testid="passwordVisibility"
                >
                  {this.state.enablePasswordField ? (
                    <IoEyeOffOutline
                      color={"#64748b"}
                      size={24}
                      data-testid="hidePassword"
                    />
                  ) : (
                    <IoEyeOutline
                      color={"#64748b"}
                      size={24}
                      data-testid="showPassword"
                    />
                  )}
                </span>
              </Box>
            </Box>

            <Box
              style={{
                margin: "30px 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "130px",
                }}
              >
                <Checkbox
                  size="medium"
                  checked={this.state.checkedRememberMe}
                  onChange={(e) => this.handleChecked(e)}
                  data-testid="checkbox"
                />
                &nbsp;
                <Typography
                  component="p"
                  style={{
                    fontSize: "17px",
                    fontWeight: 300,
                    fontFamily: "'Inter', sans-serif",
                    color: "#334155",
                  }}
                  data-testid="rememberMe"
                >
                  {this.state.labelRememberMe}
                </Typography>
              </Box>

              <Box style={{ width: "130px" }}>
                <Typography
                  component="p"
                  onClick={this.goToForgotPasswordPage}
                  style={{
                    fontSize: "17px",
                    fontWeight: 300,
                    fontFamily: "'Inter', sans-serif",
                    color: "#334155",
                    cursor: "pointer",
                  }}
                  data-testid="forgotPassword"
                >
                  {this.btnForgotPassword}
                </Typography>
              </Box>
            </Box>
          </Box>
        }
      />
      // Customizable Area End
    );
  }
}
