// Customizable Area Start
import React from "react";
import {
  
} from "@material-ui/core";

import LoginComp from "../../signuplogin/src/LoginComp.web";
import './styles/customForm.css';

import CustomFormController, {
  Props
} from "./CustomFormController.web";
import ProgressBar from "./ProgressBar.web";

export default class MyRole extends CustomFormController {
  constructor(props: Props) {
    super(props);
    
  }

  render() {
    return (
      <LoginComp 
        navigation={undefined} 
        id={"role-data"} 
        setup={true}
        onhover={this.handleIconHover}
        onleave={this.handleIconLeave}
        showInfo={this.state.showInfo}
        bgImgColor={'linear-gradient(#5591 1%, #2D00F7 100%, #5591F5 0%)'}
        heading={this.myRoleHeading}
        infoText1={this.myRoleInfoText}
        infoTextStyle={"desc-text"}
        buttonText={'Next'}
        btnDisabled={this.state.roleBtn}
        onclick={this.handleRoleClick}
        customForm={
          <ProgressBar
            navigation={undefined}
            id={""}
            role={this.state.roleStep}
            vertical={false}
            journey={false}
            institution={false}
            immediateGoal={false}
            futureGoal={false}
            step1={false}
            step2={false}
            step3={false}
            step4={false}
            step5={false}
            step6={false}
          />
        }
        formContent={
          <div className="roles-container" data-testid="mdJomdJourneyContainer">
            {this.myRoles.map((roles, index) => {
              const isSelected = this.state.selectedRole === this.roleToApiAttribute[roles.name];
              return (
                <div key={roles.id} className={`${isSelected ? 'roles-wrapper roles-selected-wrapper' : 'roles-wrapper'}`} data-testid={`${roles.name}-wrapper`}>
                  <span className={`${isSelected ? 'roles-icon  roles-selected-icon' : 'roles-icon'}`} data-testid={`${roles.name}-iconWrapper`}>
                    <img src={roles.icon} alt={`${roles.name}-icon`} data-testid={`${roles.name}-icon`} />
                  </span>
                  <label
                    htmlFor={`roles-${index}`}
                    className={`${isSelected ? 'roles-label  roles-selected-label' : 'roles-label'}`}
                    data-testid={`${roles.name}-label`}
                  >
                    {roles.name}
                    <input 
                      type="radio" 
                      id={`roles-${index}`}
                      name="roles"
                      value={roles.name}
                      className="radioBtn"
                      onChange={(e)=>this.handleMyRole(e)} 
                      checked={isSelected}
                      data-testid={`${roles.name}-roles`} 
                    />
                  </label>
                </div>
              )
            })}
          </div>
        }
      />
    );
  }
}





// Customizable Area End