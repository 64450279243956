import React from 'react'
import CircleChart from './CircleChart'
import ChartHours from './ChartHours'

function LookDisign() {

    let content: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding:'10px',
        // background: 'rgb(23, 33, 48)',
        background: 'linear-gradient(0deg, #1E293B, #1E293B), linear-gradient(0deg, rgba(5, 13, 21, 0.5), rgba(5, 13, 21, 0.5))',
        minHeight:"auto"

    }
    let left: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px',
        padding: "20px",
        width: '28%',
        alignItems: "center",
        border: '1px solid gray',
        height:'186px'
    }
    let right: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px',
        width: '70%',
        border: '1px solid gray',
        padding:'20px',
        height:'186px'

    }
    return (
        // <div className='main' style={Main}>
          
            <div className='content' style={content}>
                <div className='left' style={left}>
                    <h3 style={{color:'white',fontWeight:700,fontSize:'12px'}}>Grade Average</h3>
                    <CircleChart />
                    <h3 style={{color:'white',fontWeight:300,fontSize:'12px'}}>Solid Work keep Going</h3>
                </div>
                <div className='right p-2' style={right}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <h3 style={{color:'white',fontWeight:700,fontSize:'12px'}}>Hourse Activity</h3>
                        <h3 style={{color:'white',fontWeight:300,fontSize:'12px'}}><span style={{color:"rgba(114, 239, 221, 1)"}} >15%</span> Highter then Last week</h3>
                    </div>
                    <div>
                        <ChartHours />
                    </div>

                </div>
            </div>
        //     {/* <div className='footer'>

        //     </div> */}
        // </div>
    )
}

export default LookDisign