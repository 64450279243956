Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "keywordsearch";
exports.labelBodyText = "keywordsearch Body";
exports.getSearchApiEndPoint = "bx_block_keyword_search/keyword_searches";

exports.btnExampleTitle = "CLICK ME";
exports.title = "Title";
exports.rating = "Rating";
exports.director = "Director";
exports.producer = "Producer";
exports.duration = "Duration";
exports.search = "Search";
exports.movie = "Movie";
exports.noDataFound = "No data found";
// Customizable Area End