import React from "react";

// Customizable Area Start
import {
  Box,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import LoginComp from "../../signuplogin/src/LoginComp.web";
import '../../signuplogin/src/styles/loginForm.css';
import { password, bgImg } from "./assets";
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ForgotPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <LoginComp 
          navigation={undefined} 
          id={""}
          pgIcon={password}
          bgImg={bgImg}
          backBtn={true}
          goBack={this.goBack}
          heading={this.heading}
          headingStyle={"headingStyle"} 
          infoText1={this.infoText1} 
          infoText2={this.infoText2} 
          infoTextMt2={"10px"}
          onclick={this.sendPasswordResetLink}
          buttonText={this.btnNext}
          popupError={this.state.error}
          popupErrorStyle={"popupErrorStyle"}
          warning={this.state.warning}
          form={
            <Box className="loginFormContainer">
              <Box className="input-wrapper">
                <input 
                  type="text" 
                  id="email" 
                  name="email" 
                  value={this.state.email}
                  onChange={(e) => this.handleEmailChange(e)} 
                  className="input-field forgot-paswrd"
                  data-testid="email"
                  required />
                  <label htmlFor="email" className="label-text" data-testid="emailLabel">{this.labelEmail}</label>
              </Box>
              </Box>
          }/>
       // Customizable Area End
    );
  }
}
