// Customizable Area Start
import React from "react";
import // Customizable Area Start
// Customizable Area End
"@material-ui/core";

// Customizable Area Start
import LoginComp from "../../signuplogin/src/LoginComp.web";
import { loaderBg } from "./assets";
import "./styles/customForm.css";

import CustomFormController, {
  Props
} from "./CustomFormController.web";
// Customizable Area End

export default class PersonalizationLoader extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LoginComp
        navigation={undefined}
        id={""}
        setup={false}
        bgImgColor={`url(${loaderBg})`}
        infoText1={this.loaderInfoText}
        infoTextStyle={"desc-text"}
        backBtn={false}
        goBack={this.goBack}
        btnDisplay={"none"}
        formContent={
          <div className="progress-container" data-testid="progressContainer">
            <div className="progress-wrapper" data-testid="progress-wrapper">
              <span style={{ left: `${Math.min(this.state.progress, 97)}%` }} className="progress" data-testid="progress">
                {this.state.progress}%
              </span>
              <progress
                style={{ width: `${this.state.progress}%` }}
                className="progress-bar"
                value={this.state.progress}
                max={"100%"}
                data-testid="progress-bar"></progress>
            </div>
            <div className="fact">
              <p data-testid="fact">{this.loaderFact} 🫀</p>
            </div>
          </div>
        }
      />
      // Customizable Area End
    );
  }
}
// Customizable Area End
