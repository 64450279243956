import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import AuthComp from "./AuthComp.web";
import { validateEmailImg, medEdCloudLogo } from "./assets";
import { Box, Typography } from "@material-ui/core";
import './styles/authComp.css';
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "../../email-account-registration/src/EmailAccountRegistrationController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ValidateEmail extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
        <AuthComp 
          navigation={undefined} 
          id={""}
          factsBgImg={validateEmailImg}
          backBtn={false}
          goBack={this.goBack}
          heading1={this.heading1ValidateEmail}
          heading3={this.heading3ValidateEmail}
          validateEmail={true}
          infoTextHeading={this.infoTextHeading}
          infoText={this.infoText}
          colouredInfoText={this.appName}
          popupError={this.state.error}
          warning={this.state.warning}
          buttonText={this.btnTextResendLink}
          onclick={this.resendEmailVerfLink}
          iconMsg={
            <Box className="validateEmailMsgContainer" data-testid="validateEmailMsgContainer">
              <Box className="logoWrapper">
                <img src={medEdCloudLogo} alt="medEdCloud-logo" />
              </Box>
              <Box className="validateEmailMsgWrapper">
                <Typography variant="h2" component="h2" className="validateEmailMsgheading" data-testid="validateEmailMsgheading">{this.headingWelcome}&nbsp;{this.appName}!</Typography>
                <Typography component="p" className="validateEmailText" data-testid="validateEmailText">{this.validateEmailText}</Typography>
              </Box>
            </Box>
          }          
          />
       // Customizable Area End
    );
  }
}


