import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {setStorageData } from "../../../framework/src/Utilities";

import { ReactNode } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  auth?: any;
  setup?: boolean;
  goToSignup?: any;
  onhover?: any;
  onleave?: any;
  showInfo?: boolean;
  pgIcon?: any;
  contentContainerStyle?: any;
  bgImg?: any;
  bgImgPosition?: any;
  bgImgColor?: any;
  heading?: string;
  headingStyle?: string;
  infoText1?: string;
  infoText2?: string;
  infoText3?: string;
  infoTextMt1?: string;
  infoTextMt2?: string;
  infoTextMt3?: string;
  infoTextStyle?: any;
  form?: any;
  formContent?: any;
  onclick?: any;
  popupError?: any;
  popupErrorStyle?: any;
  warning?: any;
  buttonText?: string;
  btnDisplay?: string;
  btnDisabled?: any;
  backBtn?: boolean
  goBack?: any;
  customForm?: ReactNode;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  loginEmail: string;
  loginPassword: string;
  isValidEmail: boolean;
  error: string | null;
  isBtnChecked: boolean;
  isDisabled: boolean;
  warning: boolean |  null;
  validEmail: boolean | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SignuploginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginApiCallId:any
  //Properties form config
  signupText1:string = configJSON.signupText1;
  signupText2:string = configJSON.signupText2;
  signupBtnText:string = configJSON.signupBtnText;
  headingLogin:string = configJSON.headingLogin;
  placeHolderEmail:string = configJSON.placeHolderEmail;
  placeholderPassword:string = configJSON.placeHolderPassword;
  labelRememberMe:string = configJSON.labelRememberMe;
  btnForgotPassword:string = configJSON.btnForgotPassword;
  btnTextLogin:string = configJSON.btnTextLogin;

  emailReg:RegExp = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,}(?:\.[A-Z]{2,})?$/i);
  labelTitle:string = configJSON.labelTitle;
  appName:string = configJSON.appName;
  welcomeText:string = configJSON.welcomeText;
  preferencesText:string = configJSON.preferencesText;
  setupText:string = configJSON.setupText;
  setupInfoText:string = configJSON.setupInfoText;
  nextBtnText:string = configJSON.nextBtnText;
  errorAllFieldsAreMandatory:string = configJSON.errorAllFieldsAreMandatory;
  errorEmail:string = configJSON.errorEmail;
  errorInvalidEmail:string = configJSON.errorInvalidEmail;
  passwordUpperCaseValidation:string = configJSON.passwordUpperCaseValidation;
  passwordLowerCaseValidation:string = configJSON.passwordLowerCaseValidation;
  passwordNumberValidation:string = configJSON.passwordNumberValidation;
  passwordLengthValidation:string = configJSON.passwordLengthValidation;
  passwordMatchValidation:string = configJSON.passwordMatchValidation;
  errorInvalidEmailOrPassword:string = configJSON.errorInvalidEmailOrPassword;
  errorUserNotFound: string = configJSON.errorUserNotFound;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage)

      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      loginEmail: "",
      loginPassword: "",
      isValidEmail: false,
      error: "",
      isBtnChecked: false,
      isDisabled: true,
      warning: false,
      validEmail: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    ); 
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    switch (apiRequestCallId) {
      case this.loginApiCallId:
        this.handleloginApiResponse(responseJson, errorReponse);
        break;
  }  
}
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  goBack = () => {
    this.props.navigation.goBack();
  }

  goToSignUp = () => {
    this.props.navigation.navigate('SignUp');
    
  }

  goToForgotPasswordPage = () => {
    this.props.navigation.navigate('ForgotPasswordWeb');
  }

  handleBtnChecked = (e: any) => {
    this.setState({isBtnChecked: e.target.checked});
  }

  handleEmailChange = (e: any) => {
    const loginEmail = e.target.value.trim();
    const isDisabled = loginEmail === "" || this.state.loginPassword === "";

    this.setState({ loginEmail, isDisabled, error: null, warning: null, validEmail: null}, () => {
      this.handleDisabledButton();
    });
  }

  handleShowPassword = () => {
    this.setState({
      enableField: !this.state.enableField,
    });
  };

  handlePasswordChange = (e: any) => {
    const loginPassword = e.target.value.trim();
    const isDisabled = this.state.loginEmail === "" || loginPassword === "";

    this.setState({ loginPassword, isDisabled, error: null, warning: null} , () => {
      this.handleDisabledButton();
    });
  }

  handleDisabledButton = () => {
    const { loginEmail, loginPassword } = this.state;

    if (loginEmail.trim() === "" || loginPassword.trim() === "") {
      this.setState({ isDisabled: true });
    } else {
      this.setState({ isDisabled: false });
    }
  }

  handleLogin = () => {

    if (!this.emailReg.test(this.state.loginEmail)){
      this.setState({ error: this.errorInvalidEmail, validEmail: false})
      return false;
    }
    
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    }

    const attrs={
      email:this.state.loginEmail,
      password:this.state.loginPassword,
      remember_me: this.state.isBtnChecked
    }

    const data = {
      type: "email_account",
      attributes: attrs
    }

    const httpBody = {
      data: data,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.loginApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exampleAPiEndPoint
    )
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    
  }


  handleloginApiResponse = async (responseJson: any, errorReponse: any) => {
    if (responseJson.errors) {
      this.setState({ error: this.errorInvalidEmailOrPassword })
    }
    else {
      await setStorageData('signupToken', responseJson.meta.token);
      await setStorageData('rememberMe', this.state.isBtnChecked);
      await setStorageData('userName',responseJson.meta.user_name)
      this.props.navigation.navigate('Catalogue1')
    }
  }
  // Customizable Area End
}
