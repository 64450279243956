import React from "react";

// Customizable Area Start
import { Container, Box, Typography} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { medEdLogo } from "./assets";
import "./styles/loginComp.css";
import { IoChevronBack, IoCloseCircleOutline } from "react-icons/io5";
import { PiWarningCircleBold } from "react-icons/pi";
// Customizable Area End

import SignuploginController, {
  Props,
} from "./SignuploginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class LoginComp extends SignuploginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderNav = () => {
    return (
      <Box className="nav-container" data-testid="nav">
        <Box className={`${this.props.setup ? "nav-wrapper nav-wrapper-setup" : "nav-wrapper"}`}>
          <img className="logo2" src={medEdLogo} alt="medEd_logo" />
          {this.props.auth &&  <Box className="auth-wrapper" data-testid="auth-wrapper">{this.props.auth}</Box>}
          {this.props.setup &&  <Box className="auth-wrapper setup-wrapper" data-testid="setup-wrapper">
            <span className='setupLine'></span>&nbsp;
            <p className="setupText">Preferences <span style={{fontWeight: 300}}>Setup</span></p>
            <span onMouseEnter={this.props.onhover} onMouseLeave={this.props.onleave} data-testid="setupIconWrapper">
              <PiWarningCircleBold size={20} color={'#4838a3'} />
            </span>
            {this.props.showInfo && <div className="setupInfoTextWrapper">
              <p className="setupInfoText" data-testid='setupInfoText'>These questions are designed to tailor your dashboard and recommendations. Your answers will guide us in presenting the most relevant content and suggestions each time you log in.</p>
            </div>}
          </Box>}
        </Box>
      </Box>
    );
  };

  renderHeading = () => {
    return (
      <Box className="heading-container">
        <Box className="heading-wrapper">
          {this.props.backBtn && <button className="back-btn" data-testid="backBtn" onClick={this.props.goBack}>
            <IoChevronBack color="#ffffff" className="back-btn-icon" />
          </button>}
          <Typography
            component="h2"
            className={`heading heading-auth ${this.props.headingStyle}`}
            data-testid="heading"
          >{this.props.heading}</Typography>
        </Box>

        <Box>
          {this.props.infoText1 && <Typography
            component="p"
            style={{ marginTop: this.props.infoTextMt1 }}
            className={`info-text ${this.props.infoTextStyle}`}
            data-testid="info-text"
          >{this.props.infoText1}</Typography>}

          {this.props.infoText2 && <Typography
            component="p"
            style={{ marginTop: this.props.infoTextMt2 }}
            className="info-text"
            data-testid="info-text2"
          >{this.props.infoText2}</Typography>}

          {this.props.infoText3 && <Typography
            component="p"
            style={{ marginTop: this.props.infoTextMt3 }}
            className="info-text"
            data-testid="info-text3"
          >{this.props.infoText3}</Typography>}
        </Box>
      </Box>
      
    );
  };

  renderButton = () => {
    return (
      <Box style={{ transform: "translateY(20px)", display: this.props.btnDisplay}} className="submitBtn-wrapper">
        <button
          type="button"
          onClick={this.props.onclick}
          className="button"
          style={{ background: this.props.btnDisabled ? 'none' : 'linear-gradient(to right, #6201ea, #26c5f3)', backgroundColor: this.props.btnDisabled ? '#d9d9d9' : 'none', color: this.props.btnDisabled ? '#00000060' : 'ffffff'}}
          disabled={this.props.btnDisabled}
          data-testid="submitButton"
        >
          {this.props.buttonText ? this.props.buttonText : "Continue"}
        </button>
      </Box>
    );
  };

  renderContent = () => {
    return (
      <>
        <Box className="content-wrapper"  data-testid="content">
          <img src={this.props.pgIcon} alt={this.props.pgIcon} className="pgIcon" data-testid="pgIcon" />
            <Box className="form-content">
              {this.renderHeading()}
              {this.props.formContent && <>{this.props.formContent}</>}
              <Box
                className={`${this.props.form ? 'form-wrapper' : ''}`}
                data-testid="form-wrapper"
              >
                {this.props.form && <>{this.props.form}</>}
              </Box>

              {this.renderButton()}
            </Box>
        </Box>
      </>
    );
  };

  renderPopupError = () => {
    return (
      <Box
        style={{backgroundColor: `${this.props.popupError ? '#fee2e2' : '#fef3c7'}`, borderLeftColor: `${this.props.popupError ? '#dc2626' : '#d97705'}`}}
        className={`popup-error ${this.props.popupErrorStyle}`}
        data-testid="popup-error"
      >
        {this.props.popupError ? <IoCloseCircleOutline  color="#dc2626" size="22" /> : <PiWarningCircleBold  color="#f59e0c" size="22" />}
        <Typography style={{ color: `${this.props.popupError ? '#ff0000' : '#D97706'}`}} className="popupError-text">
         {this.props.popupError || this.props.warning}
        </Typography>
      </Box>
    );
  };

  renderBgImg = () => {
    if (this.props.bgImg) return `url(${this.props.bgImg})`;
    return this.props.bgImgColor ? this.props.bgImgColor : 'none';
  }
  
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Container
        maxWidth="xl"
        className="container-loginComp"
      >
        {this.renderNav()}
          {this.props.customForm && <>{this.props.customForm}</>}
        <Box
          className={`content-container ${this.props.contentContainerStyle}`}
          style={{ 
            backgroundImage: this.renderBgImg(), 
            backgroundPosition: this.props.bgImgPosition}}
            data-testid="content-container"
        >
          {this.renderContent()}         
        </Box>
        {(this.props.popupError || this.props.warning) && <>{this.renderPopupError()}</>}
      </Container>
      // Customizable Area End
    );
  }
}
