// Customizable Area Start
import React from "react";
import // Customizable Area Start
{ Box } from
// Customizable Area End
"@material-ui/core";

// Customizable Area Start
import AuthComp from "../../email-account-registration/src/AuthComp.web";
import { rocketImg } from "./assets";
import "./styles/customForm.css";
import '../../email-account-registration/src/styles/authComp.css';

import CustomFormController, {
  Props
} from "./CustomFormController.web";
// Customizable Area End

export default class PersonalizationComplete extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    this.handleProgress();
    return (
      // Customizable Area Start
      <AuthComp 
          navigation={undefined} 
          id={""}
          factsBgImg={rocketImg}
          bgImgStyle={"bgImgStyle"}
          backBtn={false}
          goBack={this.goBack}
          heading1={this.heading1}
          heading3={`${this.state.name}!`}
          heading3style={"h3Style"}
          validateEmail={true}
          infoTextHeading={this.infoTextHeading}
          infoText={this.infoText}
          infoTextHeadingMargin={'10px 0'}
          buttonText={this.btnText}
          onclick={this.goToDashboard}
          iconMsg={
            <Box className="msg-container" data-testid="msg-container">
              <h2 className="msg" data-testid="msg">
                <span className="gradientText">The future</span>
                <br/><i>of learning</i><br/>
                <span className="gradientText gradientText2">starts</span> 
                <br/> <span className="msg msg2"><i>here!</i></span>
              </h2>
            </Box>
          }          
          />
      // Customizable Area End
    );
  }
}
// Customizable Area End
