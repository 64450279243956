export const medEdLogo = require("../assets/medEdLogo.svg");
export const heroBg = require("../assets/heroBg.svg");
export const heroImg = require("../assets/heroImg.svg");
export const phone = require("../assets/phone.svg");
export const dynamicForm = require("../assets/dynamic_form.svg");
export const checkIcon = require("../assets/checkIcon.svg");
export const featuresImg = require("../assets/featuresImg.svg");
export const person = require("../assets/person.svg");
export const schedule = require("../assets/schedule.svg");
export const adaptiveImg = require("../assets/adaptiveImg.svg");
export const adaptiveImg2 = require("../assets/adaptiveImg2.svg");
export const healthcare = require("../assets/healthcare.svg");
export const knowledge = require("../assets/knowledge.svg");
export const innovation = require("../assets/innovation.svg");
export const marketplace = require("../assets/marketplace.svg");
export const research = require("../assets/research.svg");
export const partner1 = require("../assets/partner1.svg");
export const partner2 = require("../assets/partner2.svg");
export const partner3 = require("../assets/partner3.svg");
export const partner4 = require("../assets/partner4.svg");
export const student1 = require("../assets/student1.svg");
export const student2 = require("../assets/student2.svg");
export const student3 = require("../assets/student3.svg");
export const student4 = require("../assets/student4.svg");
export const govt1 = require("../assets/govt1.svg");
export const govt2 = require("../assets/govt2.svg");
export const govt3 = require("../assets/govt3.svg");
export const govt4 = require("../assets/govt4.svg");
export const pulseBg = require("../assets/pulseBg.svg");
export const testifier1 = require("../assets/testifier1.svg");
export const testifier2 = require("../assets/testifier2.svg");
export const testifier3 = require("../assets/testifier3.svg");
export const testimonialsBg = require("../assets/testimonialsBg.svg");
export const quote = require("../assets/quote.svg");
export const pricingBg = require("../assets/pricingBg.svg");
export const lock = require("../assets/lock.svg");
export const star = require("../assets/star.svg");
export const diamond = require("../assets/diamond.svg");
export const facebook = require("../assets/facebook.svg");
export const instagram = require("../assets/instagram.svg");
export const xIcon = require("../assets/xIcon.svg");
export const linkedin = require("../assets/linkedin.svg");
export const youtube = require("../assets/youtube.svg");