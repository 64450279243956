import React from "react";
import "./Header.css";
import GaugeChart from 'react-gauge-chart'

{/* <GaugeChart id="gauge-chart1" /> */}

function CircleChart() {
  return (   
    
<GaugeChart id="gauge-chart5"
  nrOfLevels={420}
  arcsLength={[0.7, 0.3]}
  colors={[ "rgba(114, 239, 221, 1)","#25454B"]}
  percent={0.80}
  arcWidth={0.5}
  arcPadding={0.02}
/> 
   )
}

export { CircleChart };
export default CircleChart;
