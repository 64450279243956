Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.LoginSuccessMessage="Login Successfully"
exports.exampleAPiEndPoint = "login/login";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "signuplogin";
exports.labelBodyText = "signuplogin Body";
exports.btnExampleTitle = "CLICK ME";

exports.signupText1 = "Don't have an";
exports.signupText2 = "account?";
exports.signupBtnText = "Sign Up";
exports.headingLogin = "Welcome Back!";
exports.placeHolderEmail = "Email address";
exports.placeHolderPassword = "Password";
exports.btnTxtLogin = "Login";
exports.labelRememberMe = "Remember me";
exports.btnForgotPassword = "Forgot Password?";

exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.preferencesText = "Preferences";
exports.setupText = "Setup";
exports.setupInfoText = "These questions are designed to tailor your dashboard and recommendations. Your answers will guide us in presenting the most relevant content and suggestions each time you log in."
exports.appName = "MedEd Cloud";
exports.headingWelcome = "Welcome back to";
exports.nextBtnText = "Go!";
exports.errorEmail = "Please input email address";
exports.errorInvalidEmail = "Please enter a valid email address";
exports.passwordUpperCaseValidation = "At least one capital letter";
exports.passwordLowerCaseValidation = "At least one lowercase letter";
exports.passwordNumberValidation = "At least one number";
exports.passwordLengthValidation = "At least eight characters";
exports.passwordMatchValidation = "Passwords do not match";
exports.validateEmailText = "We have sent a link to your email address. Head over there now and validate your email address so we can start to personalise your journey!";
exports.emailValidatedText = "Thanks for validating! Now, let's personalise your journey on MedEd Cloud.";
exports.btnTextLogin = "Login";
exports.btnTextResendLink = "Resend Link";
exports.btnTextPersonalise = "Start Personalising";
exports.errorInvalidEmailOrPassword = "Invalid email or password";
exports.errorAllFieldsAreMandatory = "Please complete all required fields";
exports.errorUserNotFound = "It seems there's no account linked to this email. Sign up to connect with us!"
// Customizable Area End