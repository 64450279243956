export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const medEdLogo = require("../assets/medEdLogo.svg");
export const bgEllipsis = require("../assets/bgEllipsis.svg");
export const pulseImg = require("../assets/pulseImg.svg");
export const thumbPrintImg = require("../assets/thumbPrintImg.svg");
export const validateEmailImg = require("../assets/validateEmailImg.svg");
export const skipArrow = require("../assets/skipArrow.svg");
export const flag = require("../assets/flag.svg");
export const medEdCloudLogo = require("../assets/medEdCloudLogo.svg");
export const welcomeImg = require("../assets/welcomeImg.svg");
export const bgEllipsis2 = require("../assets/bgEllipsis2.svg");
