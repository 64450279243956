// Customizable Area Start
import React from "react";
import { 
// Customizable Area Start
  Box, 
  Button, 
  Typography
// Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import * as Yup from "yup";
import Select from "react-select";
import { Edit as EditIcon, Close as CloseIcon } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import { Formik, FormikErrors, FormikTouched } from "formik";
import Spinner from "./components/Spinner.web";

import CustomFormController, {
  Dropdown,
  Props
} from "./CustomFormController.web";
import { Touched } from "../../automaticreminders/src/AutomatedprioritisationController.web";
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string().required("*First name is required!"),
      lastName: Yup.string().required("*Last name is required!"),
      phoneNumber: Yup.string().required("*Phone number is required!"),
      email: Yup.string().required("*Email is required!"),
      organization: Yup.string().required("*Organization is required!"),
      teamName: Yup.string().required("*Team name is required!"),
      userType: Yup.string().required("*User type is required!"),
      rating: Yup.string().required("*Rating type is required!"),
      gender: Yup.string().required("*Gender is required!"),
      address: Yup.string().required("*Address is required!"),
      country: Yup.string().required("*Country is required!"),
      state: Yup.string().required("*State is required!"),
      city: Yup.string().required("*City is required!"),
      file: Yup.string().required("*File is required!").nullable(),
    });
  };

  getErrorMessage = (
    touched: FormikTouched<Touched>,
    errors: FormikErrors<Error>,
    value: string
  ) => {
    return (
      errors[value as keyof Error] && (
        <Typography
          style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
        >
          {errors[value as keyof Error]}
        </Typography>
      )
    );
  };

  getValue = (event: any) => {
    return event ? event.value : "";
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <Box>
          <p>Custom Form</p>
        </Box>
      // Customizable Area End
    );
  }
}

const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" },
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
};
// Customizable Area End
