Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email address";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-enter Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and Conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "Please complete all required fields";

exports.factsHeadingHome = "Mind-Bending Fact:";
exports.factsInfoTextHome = "Your brain generates more electrical impulses in one day than all the telephones in the world combined.";
exports.factsInfoText2Home = "Harness that power with our learning tools!";
exports.factsHeadingPassword = "Did you know?";
exports.factsInfoTextPassword = "Your fingerprint has over 40 unique ridges and valleys, similar to how your password should be uniquely yours.";
exports.factsInfoText2Password = "Keep it complex and secure!";
exports.heading1Password = "Set your";
exports.heading3Password = "password";
exports.factsHeadingCountry = "Medical Marvel";
exports.factsInfoTextCountry = "The smallest bone in your body is in your ear.";
exports.factsInfoText2Country = "Dive into the wonders of human anatomy with us!";
exports.heading1Country = "A few more";
exports.heading3Country = "details to go";
exports.heading1ValidateEmail = "Check your";
exports.heading3ValidateEmail = "inbox";
exports.infoTextHeading = "Look out for our email!"
exports.infoText = "Click the link inside to confirm your address, and you'll be all set for a customized journey with"
exports.appName = "MedEd CLOUD";
exports.appName2 = "MedEd Cloud";
exports.headingWelcome = "Welcome to";
exports.validateEmailText = "Thank you for joining us! You're just one step away from diving into an enriching and personalized educational journey.";
exports.heading1EmailValidated = "inbox";
exports.heading3EmailValidated = "inbox";
exports.infoTextHeadingEmailValidated = "Your email is now confirmed!";
exports.emailValidatedText1 = "Let's take the next step together and ";
exports.emailValidatedText2 = "your MedEd Cloud experience, ensuring it aligns perfectly with your ";
exports.emailValidatedBoldText1 = "personalize ";
exports.emailValidatedBoldText2 = "learning goals";


exports.welcomeText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
exports.homeBtnText = "Get Started"
exports.headingSignUpText = "Get Started!"
exports.placeHolderFirstName = "First name";
exports.placeHolderLastName = "Last name";
exports.terms = "I accept and agree to the MedEd Cloud";
exports.nextBtnText = "Next";
exports.errorFirstName = "Please enter a valid First Name";
exports.errorFirstNameLength = "First name must be at least two characters";
exports.errorLastName = "Please enter a valid Last Name";
exports.errorLastNameLength = "Last name must be at least two characters";
exports.errorEmail = "Please input email address";
exports.errorInvalidEmail = "Please enter a valid email address";
exports.errorTermsAndConditions = "Please accept terms and conditions to continue";
exports.passwordUpperCaseValidation = "1 uppercase";
exports.passwordLowerCaseValidation = "1 lowercase";
exports.passwordNumberValidation = "1 number";
exports.passwordLengthValidation = "8 characters";
exports.passwordSpecialCharValidation = "1 special character";
exports.passwordMatchValidation = "Looks like your passwords aren't matching. Please review!";
exports.passwordRequirement = "Your password isn't matching up with our guidelines. Try again!";
exports.errorPasswordLength = "Please review, maximum 16 allowed characters.";
exports.labelCountry = "Country";
exports.placeholderCountry = "Select Country";
exports.labelState = "State";
exports.placeholderState = "Select State";
exports.labelCountryCode = "Country Code";
exports.labelPhone = "Phone";
exports.errorStateSelected = "Please choose a state";
exports.errorCountrySelected = "Please choose a country";
exports.errorCountryCode = "Please choose a phone code";
exports.errorInvalidPhone = "That doesn't look like a valid phone number. Please double-check.";
exports.errorPhonenumber = "Please enter phone number";
exports.btnTextLogin = "Login";
exports.btnTextResendLink = "Resend Link";
exports.btnTextPersonalise = "Start Personalising Now!";

exports.contentTypeApiAddDetail = "application/json";
exports.accountsAPiEndPoint = "account/accounts";
exports.apiMethodTypeAddDetail = "POST";
exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.getMethod = "GET";
exports.postMethod = "POST";
exports.contentType = "application/json";
exports.signupEndpoint = "account/accounts";
exports.termsAndConditionsEndpoint = "bx_block_content_management/terms_and_conditions";
exports.emailVerificationEndpoint = "account_block/accounts/email_confirmations";
exports.resendEmailApiEndpoint = "account_block/accounts/resend_mail?email=";
exports.countriesEndpoint = "account_block/accounts/get_list_countries";
exports.statesEndpoint = "account_block/accounts/state_select_by_country_code?country_code=US";
exports.checkEmailUniqnessEndpoint = "account_block/accounts/check_email_uniqness";
// Customizable Area End
