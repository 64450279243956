import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  neurology,
  Bulb,
  Spacks,
  graph,
  filter,
  question,
  exam,
  skip_icon,
} from "./assets";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import Footer from "./Footer.web";
import "./Header.css";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AddIcon from "@material-ui/icons/Add";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ScheduleIcon from "@material-ui/icons/Schedule";
import GraphChart from "./GraphChart";
import SwipeableViews from "react-swipeable-views";
// Customizable Area End

import Catalogue1Controller, { Props } from "./Catalogue1Controller";
import Header from "./Header.web";

export default class KnowledgeTrainingCenter extends Catalogue1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderPaginationDots = () => {
    const { questions, questionsPerPage } = this.state;
    const totalItems = questions.length + 1; 
    const pageCount = Math.ceil(totalItems / questionsPerPage);

    return (
      <Box
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {Array.from({ length: pageCount }).map((_, index) => (
          <Button
            key={index}
            onClick={() => this.handlePageChange(index)}
            style={{
              backgroundColor:
                this.state.currentPage === index ? "#6200EA" : "#e0e0e0",
              borderRadius: "50%",
              width: "10px",
              height: "10px",
              margin: "5px",
            }}
          />
        ))}
      </Box>
    );
  };

  updateGlow = (rotate: number, color: string) => {
    this.setState({
      glowParams: {
        rotate,
        color,
      },
    });
  };

  getBackground = () => {
    const { color } = this.state.glowParams;
    return `radial-gradient(
      20.95% 20.95% at 50.16% 20.95%,
      #ffffff 0%,
      ${color} 30%,
      #0f172a00 130%
    )`;
  };

  formatContinueLearningDuration = (duration: string) => {
    const [hours, minutes] = duration.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const formattedHours = Math.floor(totalMinutes / 60);
    const formattedMinutes = totalMinutes % 60;
    return `${formattedHours}h ${formattedMinutes}min`;
  };
  continueLearning = () => {
    return (
      <Box className="cardMainContainer">
        <Box style={webStyle.rowDirection}>
          <h4 style={webStyle.subHeadingText}>Continue Learning</h4>
          <Box
            style={{
              textTransform: "capitalize",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "20px",
              justifyContent: "space-around",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <img src={filter} style={{ paddingLeft: "10px" }} />
            <Button
              style={{
                textTransform: "capitalize",
                fontWeight: 300,
                color: "white",
                fontSize: "14px",
              }}
            >
              {" "}
              Filter
            </Button>
          </Box>
        </Box>
        <Box className="CradBox">
          {this.state.continueLearning?.map((card: any) => {
            const continueCard = card.attributes;
            return (
              <Box className="cardMapContainer">
                <Box style={{ position: "relative" }}>
                  <img
                    src={continueCard.image}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                      borderTopLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                    }}
                    alt="Our Image"
                  />
                  <Box
                    style={{
                      position: "absolute",
                      bottom: "24px",
                      left: "25px",
                      width: "88%",
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body1"
                        style={{
                          fontStyle: "italic",
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "#FFFFFF",
                        }}
                      >
                        {continueCard.first_unit_name}
                      </Typography>
                      <Box>
                        <Typography className="cardTitle">
                          {continueCard.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        alignItems: "end",
                        display: "flex",
                        overflow: "hidden",
                      }}
                    >
                      <Button
                        variant="contained"
                        startIcon={<PlayCircleFilledIcon />}
                        size="small"
                        style={{
                          color: "white",
                          textTransform: "capitalize",
                          backgroundColor: "rgba(205, 205, 205, 0.5)",
                          fontSize: "12px",
                          height: "24px",
                          width: "94px",
                          borderRadius: "20px",
                        }}
                      >
                        Preview
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box
                  style={{
                    padding: "10px 20px",
                    marginTop: "-5px 5px",
                    borderBottomLeftRadius: "16px",
                    backgroundColor: "#172130",
                    borderBottomRightRadius: "16px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                  >
                    <Box style={{ display: "flex" }}>
                      <LibraryBooksIcon
                        style={{ color: "white", marginRight: 5 }}
                        fontSize="small"
                      />
                      <Typography
                        style={{
                          color: "#FFFFFF",
                          fontFamily: "Raleway",
                          fontSize: "13px",
                        }}
                      >
                        {continueCard.completed_units.length > 0}
                        {continueCard.completed_units.length > 0 ? "/" : null}
                        {continueCard.all_units_count} courses
                      </Typography>
                    </Box>
                    <Box style={{ display: "flex" }}>
                      <ScheduleIcon
                        fontSize="small"
                        style={{ color: "white", marginRight: 5 }}
                      />
                      <Typography
                        style={{ color: "#FFFFFF", fontSize: "13px" }}
                      >
                        {this.formatContinueLearningDuration(
                          continueCard.duration
                        )}
                      </Typography>
                    </Box>
                    <Box
                      fontSize="small"
                      style={{ display: "flex", marginRight: 5 }}
                    >
                      <PeopleOutlineOutlinedIcon style={{ color: "white" }} />
                      <Typography
                        style={{ fontSize: "13px", color: "#FFFFFF" }}
                      >
                        level {continueCard.level}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      marginTop: "20px",
                      justifyContent: "space-between",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div style={{ overflow: "auto", height: "100px" }}>
                      <Typography
                        style={{ fontSize: "14px", color: "#FFFFFF" }}
                      >
                        {continueCard.description}
                      </Typography>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Button
                        fullWidth
                        variant="contained"
                        style={{
                          color: "white",
                          borderRadius: "20px",
                          bottom: "10px",
                          background:
                            "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
                        }}
                      >
                        Enroll
                      </Button>
                    </div>
                  </Box>
                  <Box style={{ textAlign: "center" }} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };
  onStartQuestionBank = () => {
    const { questions, currentPage, questionsPerPage } = this.state;
    const totalItems = [{ isNewTask: true }, ...questions];
    const pageCount = Math.ceil(totalItems.length / questionsPerPage);
    const currentQuestions = Array.from(
      { length: pageCount },
      (_, pageIndex) => {
        const startIndex = pageIndex * questionsPerPage;
        return totalItems.slice(startIndex, startIndex + questionsPerPage);
      }
    );


    return (
      <div className="mainQueSimulateContainer" data-testid="main-container">
        <Box className="QuestionsContainer" data-testid="questions-container">
        <SwipeableViews
            index={currentPage}
            onChangeIndex={(index: any) => this.handlePageChange(index)}
            enableMouseEvents
          >
        {currentQuestions.map((questionsGroup, groupIndex) => (
          <Box key={groupIndex} style={{ display: "flex", flexDirection: "row" }}>
          
            {questionsGroup.map((qu: any, index: number) => {
            if (qu.isNewTask) {
              return(
                <Box className="newTaskQu" data-testid="new-task">
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "98px",
                    height: "119px",
                    marginBottom: "67px",
                  }}
                >
                  <Button
                    data-testid="start-question-bank-button"
                    style={{
                      background:
                        "linear-gradient(90deg, rgba(97,32,201,1) 0%, rgba(64,93,219,1) 34%, rgba(49,120,227,1) 58%, rgba(0,212,255,1) 100%)",
                      borderRadius: "50%",
                      marginBottom: "20px",
                      padding: "15px",
                    }}
                  >
                    <AddIcon style={{ color: "white" }} />
                  </Button>
                  <Box
                    style={{
                      textTransform: "capitalize",
                      borderRadius: "34px",
                      borderTop: 10,
                      opacity: "50%",
                    }}
                  >
                    <Typography
                      data-testid="new-task-text"
                      style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        color: "rgba(255,255,255,1)",
                        textAlign: "center",
                      }}
                    >
                      Start a new Question Bank!
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      textTransform: "capitalize",
                      borderRadius: "34px",
                      opacity: "50%",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 300,
                        fontSize: "14px",
                        color: "rgba(255,255,255,1)",
                      }}
                    >
                      Click here to begin <br />
                      customizing your quiz.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              )
            }

              const Que = qu.attributes;
              return (
                <Box
                  className="quesbanks"
                  data-testid="question-box"
                  style={{
                    backgroundColor: " #172130",
                    width: "331px",
                    height: "416px",
                    borderRadius: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "165px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "rgba(30, 41, 59, 0.5)",
                      overflow: "hidden",
                      borderRadius: "19px 19px 0px 0px",
                      border: "1px solid rgba(30,41,59,1)",
                    }}
                  >
                    <Button
                      style={{
                        background: "rgba(51, 65, 85, 0.6)",
                        borderRadius: "50%",
                        marginBottom: "20px",
                        padding: "15px",
                      }}
                    >
                      <img
                        src={exam}
                        style={{
                          width: "24px",
                          height: "24px",
                          color: "white",
                        }}
                      />
                    </Button>

                    <span
                      style={{
                        fontWeight: 900,
                        fontSize: "20px",
                        color: "white",
                      }}
                    >
                      {Que.name}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        padding: "5px",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "rgba(255, 255, 255, 1)",
                          fontWeight: 300,
                          fontSize: "12px",
                        }}
                      >
                        {Que.id} questions .{" "}
                      </span>
                      <div
                        style={{
                          backgroundColor: "rgba(114, 239, 221, 1)",
                          padding: "5px",
                          borderRadius: "20px",
                          color: "white",
                          fontWeight: 800,
                          fontSize: "12px",
                          opacity: "50%",
                          marginLeft: "5px",
                        }}
                      >
                        100/100
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ margin: "20px" }}>
                      <div style={{ marginBottom: "15px", display: "flex" }}>
                        <img src={skip_icon} />
                        <div
                          style={{
                            backgroundColor: "white",
                            height: "20px",
                            width: "1px",
                            marginLeft: "10px",
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "10px",
                          }}
                        >
                          <span
                            style={{
                              color: "white",
                              fontSize: "10px",
                              fontWeight: 300,
                            }}
                          >
                            JOURNEY
                          </span>
                          <span
                            style={{
                              color: "white",
                              fontSize: "13px",
                              fontWeight: 400,
                            }}
                          >
                            {Que.category}
                          </span>
                        </div>
                      </div>

                      <div style={{ display: "flex", marginBottom: "15px" }}>
                        <img src={skip_icon} />
                        <div
                          style={{
                            backgroundColor: "white",
                            marginLeft: "10px",
                            height: "20px",
                            width: "1px",
                          }}
                        />
                        <div
                          style={{
                            flexDirection: "column",
                            marginLeft: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 300,
                              color: "white",
                              fontSize: "10px",
                            }}
                          >
                            COURSE
                          </span>
                          <span
                            style={{
                              fontSize: "13px",
                              fontWeight: 400,
                              color: "white",
                            }}
                          >
                            {Que.course}
                          </span>
                        </div>
                      </div>

                      <div style={{ display: "flex", marginBottom: "15px" }}>
                        <img src={skip_icon} />
                        <div
                          style={{
                            marginLeft: "10px",
                            backgroundColor: "white",
                            width: "1px",
                            height: "20px",
                          }}
                        />
                        <div
                          style={{
                            marginLeft: "10px",
                            flexDirection: "column",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 300,
                              fontSize: "10px",
                              color: "white",
                            }}
                          >
                            UNIT
                          </span>
                          <span
                            style={{
                              fontSize: "13px",
                              fontWeight: 400,
                              color: "white",
                            }}
                          >
                            {Que.unit}
                          </span>
                        </div>
                      </div>

                      <div style={{ marginBottom: "15px", display: "flex" }}>
                        <img src={skip_icon} />
                        <div
                          style={{
                            height: "20px",
                            backgroundColor: "white",
                            marginLeft: "10px",
                            width: "1px",
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "10px",
                          }}
                        >
                          <span
                            style={{
                              color: "white",
                              fontSize: "10px",
                              fontWeight: 300,
                            }}
                          >
                            LESSON
                          </span>
                          <span
                            style={{
                              color: "white",
                              fontSize: "13px",
                              fontWeight: 400,
                            }}
                          >
                            {Que.lesson}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "5px 20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        style={{
                          color: "white",

                          background:
                            "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
                          borderRadius: "20px",
                          height: "32px",
                          padding: "2px",
                          overflow: "hidden",
                        }}
                      >
                        Retry Quiz
                      </Button>

                      <Button
                        fullWidth
                        data-test-id="ViewResult"
                        className="button-with-gradient-border"
                        variant="outlined"
                        style={{
                          borderRadius: "100px",
                          height: "32px",
                          padding: "2px",
                          textTransform: "capitalize",
                          color: "white",
                          fontWeight: 800,
                          background:
                            "linear-gradient(to right,  #6200EA 0%,#26C5F3 100%)",
                          overflow: "hidden",
                          marginLeft: "5px",
                        }}
                      >
                        <span
                          style={{
                            width: "100%",
                            height: "28px",
                            backgroundColor: "#1E293B",
                            borderRadius: "100px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          View Results
                        </span>
                      </Button>
                    </div>
                  </div>
                </Box>
              );
            })}
          </Box>
        ))}
        </SwipeableViews>
        <div className="paginationdots">{this.renderPaginationDots()}</div>
        </Box>
      </div>
    );
  };

  taskSelection = () => {
    const { glowParams } = this.state;

    return (
      <div className="taskContainer">
        <div>
          <h2
            className="welcomeTxt"
            style={{ width: "80%", display: "flex", alignItems: "center" }}
          >
            My Services
            <ArrowRightIcon style={{ margin: "0 10px" }} />
            <span className="subMenuText">Knowledge & Training Center</span>
          </h2>
          <span
            className="welcomeBottom-border"
            style={{ display: "block", width: "80%" }}
          />
        </div>

        <div className="questionImg">
          <img src={question} style={webStyle.imgQ} />
        </div>
        <div className="UpperKnowledgeContainer">
          <div style={{ marginTop: "-100px" }}>
            <Box className="knowledgeLineContainer">
              {glowParams.rotate !== -1 && (
                <div
                  style={{
                    transform: `rotate(${glowParams.rotate}deg)`,
                    background: this.getBackground(),
                  }}
                  data-test-id="GlowBtn"
                  className="Glow"
                />
              )}

              <div>
                <Box className="backShadowFirst catalogue">
                  <div
                    className="firstBtn commonBox"
                    style={{
                      boxShadow:
                        this.state.currentGlow == "firstBox"
                          ? "rgba(114, 239, 221, 0.5) 0px 40px 50px 0px,rgba(114, 239, 221, 0.6) 0px 0px 10px"
                          : "rgba(114, 239, 221, 0.6) 0px 40px 50px 0px,rgba(114, 239, 221, 0.6) 0px 0px 10px",
                      opacity:
                        this.state.currentGlow == "firstBox" ? "2" : "1.9",
                    }}
                    onMouseEnter={() =>
                      this.setState({ currentGlow: "firstBox" })
                    }
                    onMouseLeave={() => this.setState({ currentGlow: "" })}
                    data-test-id="firstButton"
                  >
                    <img
                      src={neurology}
                      alt="neurology"
                      className="centerIconSize"
                    />
                  </div>

                  <Box style={{ width: "130px", color: "#FFFFFF" }}>
                    <Typography className="centerIcon">
                      {" "}
                      Knowledge <br />& Training Center
                    </Typography>
                  </Box>
                </Box>

                <Box className="positionKnowledgeSecond">
                  <div
                    style={{
                      boxShadow:
                        this.state.currentGlow == "secondBox"
                          ? "#B429F9 0px 40px 50px 0px"
                          : "none",
                      opacity:
                        this.state.currentGlow == "secondBox" ? "0.88" : "0.89",
                      borderWidth: "1",
                      background:
                        "linear-gradient(to right, rgb(53, 53, 53) 0%, rgb(255, 255, 255,0.48) 100%)",
                      padding: "1",
                      //border:'1px solid rgba(166, 161, 161, 0.65)'
                    }}
                    onMouseEnter={() =>
                      this.setState({ currentGlow: "secondBox" })
                    }
                    onMouseLeave={() => this.setState({ currentGlow: "" })}
                    className="secondBtn smallBtn"
                    data-test-id="secondButton"
                  >
                    <img src={Bulb} alt="Bulb" className="leftIconSize" />
                  </div>
                  <Box className="secondBtnKnowledgeTitle">
                    <Typography
                      style={{ fontSize: "12px", width: "125" }}
                      className="leftIcon"
                    >
                      {" "}
                      Healthcare Innovation & Entrepreneurship
                    </Typography>
                  </Box>
                </Box>

                <Box className="positionKnowledgeThird">
                  <div
                    className="thirdBtn smallBtn"
                    style={{
                      boxShadow:
                        this.state.currentGlow == "thirdBox"
                          ? "#26C5F3 0px 40px 50px 0px"
                          : "none",
                      borderWidth: "1",
                      opacity:
                        this.state.currentGlow == "thirdBox" ? "0.88" : "0.89",
                      background:
                        "linear-gradient(to right, rgb(255, 255, 255,0.48) 0%, rgb(53, 53, 53) 100%)",
                      //border:'1px solid rgba(166, 161, 161, 0.65)'
                    }}
                    onMouseEnter={() =>
                      this.setState({ currentGlow: "thirdBox" })
                    }
                    onMouseLeave={() => this.setState({ currentGlow: "" })}
                    data-test-id="thirdButton"
                  >
                    <img src={Spacks} alt="Spacks" className="rightIconSize" />
                  </div>
                  <Box className="thirdBtnTitle">
                    <Typography
                      style={{ fontSize: "12px" }}
                      className="rightIcon"
                    >
                      {" "}
                      Healthcare VR/AR & Gaming
                    </Typography>
                  </Box>
                </Box>
              </div>
            </Box>
          </div>
          <div>{this.stats()}</div>
        </div>
      </div>
    );
  };

  stats = () => {
    return (
      <Box
        className={
          !this.state.startNewQuestions
            ? "knowledgeStatsContainer"
            : "graphContainer"
        }
      >
        <Box style={webStyle.rowDirection}>
          <h4 style={webStyle.subHeadingText}>Knowledge Center Stats</h4>
          <Box
            style={{
              textTransform: "capitalize",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "20px",
            }}
          >
            <Button
              variant="outlined"
              style={{
                borderRadius: "34px",
                textTransform: "none",
                fontWeight: 300,
                color: "white",
                fontSize: "14px",
              }}
            >
              {" "}
              This week
            </Button>
          </Box>
        </Box>
        {!this.state.startNewQuestions ? (
          <Box style={{ ...webStyle.statsBodyy, marginTop: "50px" }}>
            <img src={graph} />
            <Typography
              style={
                {
                  fontWeight: 700,
                  fontSize: "14px",
                  color: "rgba(255, 255, 255, 1)",
                  opacity: "30%",
                } as React.CSSProperties
              }
            >
              Stats empty? That's just the start!
            </Typography>
            <Typography
              style={{
                fontWeight: 300,
                fontSize: "14px",
                color: "rgba(255, 255, 255, 1)",
                opacity: "30%",
              }}
            >
              {" "}
              Engage with courses to unlock insights into your performance.
            </Typography>
          </Box>
        ) : (
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <GraphChart />
          </Box>
        )}
      </Box>
    );
  };

  medEdJourney = () => {
    return (
      <Box className="medMainContainer" style={{ marginTop: "50px" }}>
        <Box style={webStyle.rowDirection}>
          <h4 style={{ ...webStyle.subHeadingText, marginLeft: "10px" }}>
            MedEd Journeys
          </h4>
          <Box
            style={{
              textTransform: "capitalize",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "20px",
              justifyContent: "space-around",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <img src={filter} style={{ paddingLeft: "10px" }} />
            <Button
              style={{
                textTransform: "capitalize",
                fontWeight: 300,
                color: "white",
                fontSize: "14px",
                marginRight: "10px",
              }}
            >
              {" "}
              Filter
            </Button>
          </Box>
        </Box>

        <Box className="CradBox">
          {this.state.medEdCardDetails?.map((card: any, index: number) => {
            const medEdCard = card.attributes;
            return (
              <Box className="cardMapContainer">
                <Box style={{ position: "relative" }}>
                  <img
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                      borderTopLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                    }}
                    src={medEdCard.image}
                    alt="Our Image"
                  />
                  <Box
                    style={{
                      position: "absolute",
                      bottom: "24px",
                      left: "25px",
                      width: "88%",
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                  >
                    <Box>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            background: "rgba(51, 65, 85, 0.6)",
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <img
                            src={medEdCard.icon}
                            style={{
                              color: "white",
                              width: "10px",
                              height: "10px",
                            }}
                          />
                        </div>
                        <Typography
                          style={{
                            fontStyle: "italic",
                            fontSize: "12px",
                            fontWeight: 400,
                            color: "#FFFFFF",
                            marginLeft: 5,
                          }}
                          variant="body1"
                        >
                          {medEdCard.name}
                        </Typography>
                      </div>

                      <Box>
                        <Typography className="cardTitle">
                          {medEdCard.first_course_name}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#172130",
                    margin: "-5px 5px",
                    borderBottomLeftRadius: "16px",
                    borderBottomRightRadius: "16px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                  >
                    <Box style={{ display: "flex" }}>
                      <LibraryBooksIcon
                        style={{ color: "white", marginRight: 5 }}
                        fontSize="small"
                      />
                      <Typography
                        style={{
                          color: "#FFFFFF",
                          fontFamily: "Raleway",
                          fontSize: "13px",
                        }}
                      >
                        {medEdCard.completed_courses.length > 0}
                        {medEdCard.completed_courses.length > 0 ? "/" : null}
                        {medEdCard.all_courses_count} courses
                      </Typography>
                    </Box>
                    <Box
                      fontSize="small"
                      style={{ display: "flex", marginRight: 5 }}
                    >
                      <PeopleOutlineOutlinedIcon
                        style={{ color: "white" }}
                        fontSize="small"
                      />
                      <Typography
                        style={{ color: "#FFFFFF", fontSize: "13px" }}
                      >
                        {" "}
                        level {medEdCard.level}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      marginTop: "20px",
                      flexDirection: "column",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ overflow: "auto", height: "100px" }}>
                      <Typography
                        style={{ color: "#FFFFFF", fontSize: "14px" }}
                      >
                        {medEdCard.description}
                      </Typography>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Button
                        fullWidth
                        className="button-with-gradient-border"
                        variant="outlined"
                        style={{
                          borderRadius: "100px",
                          width: "100%",
                          height: "32px",
                          padding: "2px",
                          textTransform: "capitalize",
                          color: "white",
                          fontWeight: 800,
                          background:
                            "linear-gradient(to right,  #6200EA 0%,#26C5F3 100%)",
                          overflow: "hidden",
                        }}
                        onClick={() => this.onNewTask()}
                        data-test-id="ExploreJourney"
                      >
                        <span
                          style={{
                            width: "100%",
                            backgroundColor: "#1E293B",
                            borderRadius: "100px",
                            height: "28px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          Explore Journey
                        </span>
                      </Button>
                    </div>
                  </Box>
                  <Box style={{ textAlign: "center" }} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };

  questionBank = () => {
    return (
      <Box
        className={
          !this.state.startNewQuestions
            ? "cardMainContainer"
            : "QueNewContainer"
        }
        style={{ position: "relative" }}
      >
        <Box style={webStyle.rowDirection}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h4 style={webStyle.subHeadingText}>Question Banks</h4>
            <Button
              data-test-id="questionModal"
              onClick={() => this.setState({ isQuestionModal: true })}
              style={{
                marginLeft: "5px",
                paddingTop: "5px",
                lineHeight: "0px",
                zIndex: 2,
                minWidth: "1px",
              }}
            >
              <InfoOutlinedIcon style={{ color: "#2D00F7" }} />
            </Button>
          </div>

          <Box
            style={{
              textTransform: "capitalize",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "20px",
              overflow: "hidden",
            }}
          >
            <Button
              variant="outlined"
              style={{
                borderRadius: "34px",
                textTransform: "none",
                fontWeight: 300,
                color: "white",
                fontSize: "14px",
              }}
            >
              {" "}
              This week
            </Button>
          </Box>
        </Box>
        {!this.state.startNewQuestions ? (
          <div>
            <Box style={webStyle.statsBodyy}>
              <img src={exam} style={{ width: "50px", height: "50px" }} />
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: "14px",
                  color: "rgba(255, 255, 255, 1)",
                  opacity: "30%",
                }}
              >
                Ready to test your knowledge? This space is waiting for your
                custom question banks.
              </Typography>
              <Typography
                style={{
                  fontWeight: 300,
                  fontSize: "14px",
                  color: "rgba(255, 255, 255, 1)",
                  opacity: "30%",
                }}
              >
                Create one to simulate real exam conditions and challenge
                yourself.
              </Typography>
              <Button
                data-test-id="newTaskBtn"
                onClick={() => this.setState({ startNewQuestions: true })}
                className="button-with-gradient-border simulateBtn"
                variant="outlined"
                style={{
                  borderRadius: "100px",
                  padding: "2px",
                  textTransform: "capitalize",
                  color: "white",
                  fontWeight: 800,
                  background:
                    "linear-gradient(to right,  #6200EA 0%,#26C5F3 100%)",
                  overflow: "hidden",
                  margin: "30px 32%",
                  zIndex: 2,
                }}
              >
                <span className="spanSimulateBtn">Simulate Question Bank</span>
              </Button>
            </Box>
          </div>
        ) : (
          this.onStartQuestionBank()
        )}
        {this.state.isQuestionModal && (
          <div className="quePopUp">
            <div className="popUpMain">
              <Typography
                style={{ color: "white", fontSize: "14px", fontWeight: 275 }}
              >
                <b>
                  <i>Question Banks </i>
                </b>
                enable you to create and access{" "}
                <b>
                  <i>custom quizzes</i>
                </b>{" "}
                tailored to specific study areas, simulating real exam
                conditions. Utilize this feature to enhance your preparation,
                identify knowledge gaps, and track your progress over time.
              </Typography>
              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  cursor: "pointer",
                  color: "white",
                }}
                data-test-id="onQuestionModalClose"
                onClick={() => this.setState({ isQuestionModal: false })}
              >
                Close
              </div>
            </div>
          </div>
        )}
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.backgroundCLr}>
        <Header navigation={this.props.navigation} id={"3"} />

        {this.taskSelection()}
        {this.medEdJourney()}
        {this.state.startNewQuestions && this.continueLearning()}
        {this.questionBank()}
        <Footer navigation={this.props.navigation} id={""} />
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  statsBodyy: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    opacity: "30% !important",
  } as const,
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
  },
  medBody: {
    display: "flex",
  },
  imgIconBack: {
    width: "48px",
    height: "48px",
    borderRadius: "24px",
    background: "rgba(51, 65, 85, 1)",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  backgroundCLr: {
    backgroundColor: "rgb(28, 34, 53)",
  },
  plusBtnStyle: {
    width: "32px !important",
    height: "32px !important",
    color: "white",
    borderRadius: "50%",
    background: "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
    padding: 5,
  },
  imgQ: {
    width: 14,
    height: 20,
  },
  subHeadingText: {
    fontSize: "20px",
    fontWeight: 700,
    color: "rgba(255,255,255,1)",
  },
};
// Customizable Area End
