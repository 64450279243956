import React from 'react'

function ChartHours() {
    return (
        <div style={{position:"relative",width:'100%',
        height: '110px'}}>
        
            <div style={{ display: "flex",position:"absolute", justifyContent: "space-around", flexDirection: 'column', width:"100%", height: '100px' }}>
                {['8h', '6h', '4h', '2h'].map((day: string) => {
                    return <span data-test-id={`hours-${day}`} style={{ color: 'rgba(255, 255, 255, 1)', fontSize: '12px', fontWeight: 400, width: '100%',borderBottom:"1px solid rgba(141, 141, 141, 1)",opacity:'30%' }}>{day}</span>
                })}
            </div>
            <div style={{ display: "flex",position:"absolute", justifyContent: "space-evenly", alignItems: "flex-end", width: '100%'}}>
                {[
                    {width:"40px",day:"Mo"},
                    {width:"80px",day:"Tu"},
                    {width:"100px",day:"We"},
                    {width:"50px",day:"Th"},
                    {width:"80px",day:"Fr"},
                    {width:"90px",day:"Sa"},
                    {width:"10px",day:"Su"}
                ].map((d: any, key: number) => {
                    return <LineUp data-test-id={`day-${key}`} key={key} height={d.width} day={d.day} backgroundColor="rgba(114, 239, 221, 1)" />
                })}
            </div>
   

        </div>

    )
}
interface LineUpProps {
    width?: string,
    height: string,
    backgroundColor?: string,
    key: number,
    day?: string

}


export function LineUp({ width = "20px", height, backgroundColor = "red", key,day }: LineUpProps) {
    return (
        <div style={{display:'flex',flexDirection:"column"}}>
        <div key={key} style={{
            width,
            height,
            backgroundColor,
            borderRadius: "5px",
            border: "1px solid black",
            marginTop: '-5px'
        }}></div>
        <div style={{fontSize:'12px',  marginTop: '10px',color:'rgb(211, 211, 211)'}}>{day}</div>
        </div>
    )
}


export default ChartHours