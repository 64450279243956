import React from "react";

// Customizable Area Start
import { Container, Box, Typography } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { medEdLogo } from "./assets";
import "./styles/authComp.css";
import { IoChevronBack, IoCloseCircleOutline } from "react-icons/io5";
import { PiWarningCircleBold } from "react-icons/pi";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class AuthComp extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderNav = () => {
    return (
      <Box className="navContainer" data-testid="nav">
        <Box className="navWrapper">
          <img src={medEdLogo} alt="medEd_logo" className="logo" />
        </Box>
      </Box>
    );
  };

  renderHeading = () => {
    return (
      <Box className="headingWrapper">
        {this.props.backBtn && 
              <button className="backBtn" data-testid="backBtn" onClick={this.props.goBack}>
                <IoChevronBack color="#ffffff" className="bacBtnIcon" />
              </button>
            }
        
        {this.props.heading1 && <Typography
          variant="h1"
          component="h1"
            className={`heading heading1 ${this.props.heading1style}`}
          data-testid="heading1"
        >
        {this.props.heading1}
        </Typography>}

        {this.props.heading2 && <Typography
            variant="h1"
            component="h1"
            className={`heading heading2 ${this.props.heading2style}`}
            data-testid="heading2"
          >
        {this.props.heading2}
        </Typography>}

        {this.props.heading3 && <Typography
            variant="h1"
            component="h1"
            className={`heading heading3 ${this.props.heading3style}`}
            data-testid="heading3"
          >
        {this.props.heading3}
        </Typography>}
      </Box>
      
    );
  };

  renderValidateEmailInfoText = () => {
    return (
      <>
      <Typography
        component="p"
        className="infoText"
        style={{margin: this.props.infoTextHeadingMargin}}
        data-testid="infoTextHeading"
      >
        {this.props.infoTextHeading}
      </Typography>
      <Typography
        component="p"
        className="infoText"
        data-testid="infoText"
      >
        {this.props.infoText}&nbsp;<span
          className="colouredText">
          {this.props.colouredInfoText}
        </span>
      </Typography>
      
      </>
    );
  };

  renderEmailValidatedInfoText = () => {
    return (
      <>
      <Typography
        component="p"
        className="infoText"
        data-testid="infoTextHeading"
      >
        {this.infoTextHeadingEmailValidated}
      </Typography>
      <Typography
        component="p"
        className="infoText"
        data-testid="infoText"
      >
        {this.emailValidatedText1}<strong>
          {this.emailValidatedBoldText1}</strong>{this.emailValidatedText2}<strong>
          {this.emailValidatedBoldText2}</strong>.
      </Typography>
      
      </>
    );
  };

  renderError = () => {
    return (
      <Box
        className="errorWrapper"
        data-testid="error"
      >
        <Typography
          component="p"
        className="errorText"
        >
          {this.props.bottomError}
        </Typography>
      </Box>
    );
  };

  renderButton = () => {
    return (
      <Box style={{ transform: "translateY(20px)"}}>
        <button
          type="button"
          onClick={this.props.onclick}
          className="button"
          disabled={this.props.btnDisabled}
          data-testid="submitButton"
        >
          {this.props.buttonText ? this.props.buttonText : "Continue"}
        </button>
      </Box>
    );
  };

  renderContent = () => {
    return (
      <>
        <Box className="contentWrapper facts" data-testid="facts">
          <Box style={{ position: this.props.bgImgPosition}} className="bgImg" data-testid="bgImg">
            <img src={this.props.factsBgImg} alt="blob" style={{ width: this.props.bgImgWidth, left: this.props.bgImgLeftPosition, top: this.props.bgImgTopPosition}} className={`factsBgImg ${this.props.bgImgStyle}`} data-testid="factsBgImg"/>
            {this.props.factsHeading && <Box className="factsWrapper">
            <Typography
              variant="h2"
              component="h2"
              className="heading factsHeading"
              data-testid="factsHeading"
            >
              {this.props.factsHeading}
            </Typography>
            <p className="infoText factsInfoText" data-testid="factsInfoText">
              {this.props.factsInfoText}&nbsp;{this.props.factsIcon2}
            </p>
            <p className="infoText factsInfoText2" data-testid="factsInfoText2">
              {this.props.factsInfoText2}&nbsp;{this.props.factsIcon}
            </p>
            </Box>}
            {this.props.iconMsg && <>{this.props.iconMsg}</>}
          </Box>
        </Box>

        <Box className="contentWrapper content"  data-testid="content">
          <Box className="popupContainer">
            {this.props.warning && <>{this.renderPopupWarning(this.props.warning)}</>}
            {this.props.popupError && <>{this.renderPopupError(this.props.popupError)}</>}
          </Box>

          <Box className="formContainer">
            <Box className="formContent">
              {this.renderHeading()}
              <Box
                className="formWrapper"
              >
                {this.props.validateEmail && <>{this.renderValidateEmailInfoText()}</>}
                {this.props.emailValidated && <>{this.renderEmailValidatedInfoText()}</>}

                {this.props.form && <>{this.props.form}</>}
                {this.props.bottomError && <>{this.renderError()}</>}
                {this.renderButton()}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  renderPopupError = (error: string) => {
    return (
      <Box
        style={{backgroundColor:'#fee2e2', borderLeftColor: '#dc2626'}}
        className="popupError"
        data-testid="popupError"
      >
        <IoCloseCircleOutline  color="#dc2626" size="22" />&nbsp;
        <Typography style={{ color:'#ff0000'}} className="popupErrorText">
          {error}
        </Typography>
      </Box>
    );
  };

  renderPopupWarning = (warning: string) => {
    return (
      <Box
        style={{backgroundColor: '#fef3c7', borderLeftColor: '#d97705'}}
        className="popupError"
        data-testid="popupWarning"
      >
        <PiWarningCircleBold  color="#f59e0c" size="22" />&nbsp;
        <Typography style={{color: '#D97706'}} className="popupErrorText">
         {warning}
        </Typography>
      </Box>
    );
  };

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Container maxWidth="xl" className="container">
        {this.renderNav()}
        {this.props.terms ? <div data-testid="termsAndConditions">{this.props.terms}</div> : <Box
          className="contentContainer"
          data-testid="textBox"
        >
          {this.renderContent()}         
        </Box>}
      </Container>
      // Customizable Area End
    );
  }
}
