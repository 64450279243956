// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { FaCircle } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import './styles/progressBar.css';

import CustomFormController, {
  Props
} from "./CustomFormController.web";
// Customizable Area End

export default class ProgressBar extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderIcon = (step: boolean, pageName: string) => {
    return step 
    ? <FaCheck  size={8} color={'#fff'} data-testid={`${pageName}CheckIcon`} /> 
    : <FaCircle size={8} color={step ? '#050d1550' : '#455364'} data-testid={`${pageName}CircleIcon`} />;
  };
      
  renderCircleWrapperStyle = (step: boolean, pageName: string) => {
    return step ? `progress-circle-${pageName}` : "progress-circle";
  }

  renderProgressLineStyle = (pageStepName: boolean, pageName: string) => {
    return pageStepName ? `progress-line-${pageName}` : "progress-line";
  } 
  // Customizable Area End

  render() {
  
    return (
      // Customizable Area Start
      <div className="bar-container">
        <div className="progress-line-start"></div>

         <span
          className={this.renderCircleWrapperStyle(true, 'role')}
          data-testid="circleWrapperRole">
          {this.renderIcon(!!this.props.role, 'role')}
        </span>
        <div
          className={this.renderProgressLineStyle(!!this.props.role, 'role')}
          data-testid="progressLineRole"></div>
        
        
        <span
          className={this.renderCircleWrapperStyle(!!this.props.role, 'vertical')}
          data-testid="circleWrapperVertical">
          {this.renderIcon(!!this.props.vertical, 'vertical')}
        </span>
        <div
          className={this.renderProgressLineStyle(!!this.props.vertical, 'vertical')}
          data-testid="progressLineVertical"></div>
        
        <span
          className={this.renderCircleWrapperStyle(!!this.props.vertical, 'journey')}
          data-testid="circleWrapperJourney">
          {this.renderIcon(!!this.props.journey, 'journey')}
        </span>
        <div
          className={this.renderProgressLineStyle(!!this.props.journey, 'journey')}
          data-testid="progressLineJourney"></div>
        
        <span
          className={this.renderCircleWrapperStyle(!!this.props.journey, 'institution')}
          data-testid="circleWrapperInstitution">
          {this.renderIcon(!!this.props.institution, 'institution')}
        </span>
        <div
          className={this.renderProgressLineStyle(!!this.props.institution, 'institution')} 
          data-testid="progressLineInstitution"></div>
       
        <span
          className={this.renderCircleWrapperStyle(!!this.props.institution, 'immediateGoal')}
          data-testid="circleWrapperImmediateGoals">
          {this.renderIcon(!!this.props.immediateGoal, 'immediateGoal')}
        </span>
        <div
          className={this.renderProgressLineStyle(!!this.props.immediateGoal, 'immediateGoal')}
          data-testid="progressLineImmediateGoals"></div>
        
        <span
          className={this.renderCircleWrapperStyle(!!this.props.immediateGoal, 'futureGoal')}
          data-testid="circleWrapperFutureGoals">
          {this.renderIcon(!!this.props.futureGoal, 'futureGoal')}
        </span>
        <div
          style={{ borderRadius: "0 5px 5px 0" }}
          className={this.props.futureGoal ? "progress-line-end" : "progress-line"}
          data-testid="progressLineFutureGoals"></div>
      </div>

      // Customizable Area End
    );
  }
}
// Customizable Area End
