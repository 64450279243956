Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";

exports.fetchWeeklyActivityCountsEndPoint = "bx_block_repeatingtasks/weekly_activity_counts";
exports.fetchUpcomingMeetingsEndPoint = "bx_block_repeatingtasks/upcoming_meetings";
exports.fetchTomorrowEventsEndPoint = "bx_block_repeatingtasks/filter_by_timeframe?timeframe=tomorrow&timezone=Asia/Kolkata"
exports.fetchTasksCategoriesEndPoint = "bx_block_repeatingtasks/task_categories";
exports.fetchTodaysCalendarDataEndPoint = "bx_block_repeatingtasks/filter_by_timeframe?timeframe=date&timezone=Asia/Kolkata&month=June&year=2024&date=06/15/2024"
exports.fetchMyTasksEndPoint = "bx_block_tasks/list_tasks_by_deadline_type";
// Customizable Area End