import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showMenu: boolean;
  pricing: any;
  showFaq: string;
  name: string;
  email: string;
  message: string;
  messageCounter: boolean;
  nameError: string;
  emailError: string;
  messageError: string;
  pageNo: number;
  loadQueAns: any;
  indexPage: any;
  aboutUsPage: any;
  featureBenefit1: any;
  featureBenefit2: any;
  featureBenefit3: any;
  schools: any;
  pricingLists: any;
  activeDivId: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  //Customizable Area Start
  errorNameFieldRequired: string = configJSON.errorNameFieldRequired;
  errorEmailFieldRequired: string = configJSON.errorEmailFieldRequired;
  errorMsgFieldRequired: string = configJSON.errorMsgFieldRequired;
  errorInvalidName: string = configJSON.errorInvalidName;
  errorInvalidEmail: string = configJSON.errorInvalidEmail;
  queAnsApiCallID: any;
  onSubmitApiCallID: any;
  getAllContentApiCallID: any;
  //Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      showMenu: false,
      pricing: "student",
      showFaq: "",
      name: "",
      email: "",
      message: "",
      messageCounter: false,
      nameError: "",
      emailError: "",
      messageError: "",
      pageNo: 1,
      loadQueAns: [],
      indexPage: [],
      aboutUsPage: [],
      featureBenefit1: [],
      featureBenefit2: [],
      featureBenefit3: [],
      schools: [],
      pricingLists: [],
      activeDivId: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.queAnsApiCallID) {
        this.setState({ loadQueAns: responseJson });
      } else if (apiRequestCallId === this.getAllContentApiCallID) {
        console.log("ALl data????????", responseJson);
        this.setState({
          indexPage: responseJson.index_page_content,
          aboutUsPage: responseJson.about_us_content,
          featureBenefit1: responseJson.features_benefits_first_section_content,
          featureBenefit2:
            responseJson.features_benefits_second_section_content,
          featureBenefit3: responseJson.features_benefits_third_section_content,
          schools: responseJson.schools_students_government_agencies_content,
          pricingLists: responseJson.price_page_content,
        });
      } else if (apiRequestCallId === this.onSubmitApiCallID) {
        if (responseJson.errors) {
          alert(responseJson.errors);
        } else {
          alert("Your Details Submitted Successfully.");
        }
      }
    }
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin = () => {
    this.props.navigation.navigate("Signuplogin");
  };

  goToSignup = () => {
    this.props.navigation.navigate("SignUp");
  };

  goToTermsAndConditions = () => {
    this.props.navigation.navigate("TermsAndConditions");
  };

  handleFaqClick = (id: string) => {
    this.setState(
      (prevState) => ({
        showFaq: prevState.showFaq === id ? "" : id,
      }),
      () => console.log("showFaq:::", this.state.showFaq)
    );
  };

  handleMenuIcon = () => {
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));
  };

  handleLinkClick = () => {
    this.setState({ showMenu: false });
  };

  handlePricing = (type: string) => {
    this.setState({ pricing: type });
    this.allContentDynamic(type);
  };

  handlePageNoClick = (num: number) => {
    this.setState({ pageNo: num });
  };

  handleClick = (clickedDivId: any) => {
    this.setState({ activeDivId: clickedDivId });
  };

  renderClasses = (priceType: string) => {
    const { activeDivId } = this.state;

    if (activeDivId === 'Essential') {
      return {
        divClassName: ` ${priceType === 'Essential' ? 'order2' : ''} ${priceType === 'Advanced' ? 'order1' : ''} ${priceType === 'Professional' ? 'order3' : ''}`
      };
    } else if (activeDivId === 'Advanced') {
      return {
        divClassName: ` ${priceType === 'Essential' ? 'order1' : ''} ${priceType === 'Advanced' ? 'order2' : ''} ${priceType === 'Professional' ? 'order3' : ''}`
      };
    } else if (activeDivId === 'Professional') {
      return {
        divClassName: `${priceType === 'Essential' ? 'order1' : ''} ${priceType === 'Advanced' ? 'order3' : ''} ${priceType === 'Professional' ? 'order2' : ''}`
      };
    } else {
      return {
        divClassName: ''
      };
    }
  };

  handleName = (e: any) => {
    this.setState({ name: e.target.value, nameError: "" });
  };

  handleEmail = (e: any) => {
    this.setState({ email: e.target.value, emailError: "" });
  };

  handleMessage = (e: any) => {
    let message = e.target.value;
    this.setState(
      {
        message,
        messageCounter: true,
        messageError: "",
      },
      () => {
        if (message === "") {
          this.setState({ messageCounter: false });
        }
      }
    );
  };

  handleNameTrim = () => {
    let name = this.state.name.trim();

    this.setState({ name }, () => {
      this.submitMessage();
    });
  };

  handleSubmitMessage = () => {
    let error = false;
    let emailReg = new RegExp(
      /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,}(?:\.[A-Z]{2,})?$/i
    );
    let textReg = new RegExp(/^[a-zA-Z\s]+$/);

    if (!this.state.name) {
      this.setState({ nameError: this.errorNameFieldRequired });
    } else if (!textReg.test(this.state.name)) {
      this.setState({ nameError: this.errorInvalidName });
    }

    if (!this.state.email) {
      this.setState({ emailError: this.errorEmailFieldRequired });
    } else if (!emailReg.test(this.state.email)) {
      this.setState({ emailError: this.errorInvalidEmail });
    }

    if (!this.state.message) {
      this.setState({ messageError: this.errorMsgFieldRequired });


    }

    if (this.state.name && this.state.email && this.state.message) {
      this.onSubmitForm();
    }
    error = true;
    return error;
  };

  submitMessage = () => {
    this.handleSubmitMessage();
  };

  componentDidMount = async () => {
    await this.onLoadQueAns();
    await this.allContentDynamic(this.state.pricing);
  };

  onLoadQueAns = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      //  token: await getStorageData('signupToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.queAnsApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.queAnsLandingPageEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  allContentDynamic = (type: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      //  token: await getStorageData('signupToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllContentApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllpageContentEndPoint}${type}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onSubmitForm = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      //  token: await getStorageData('signupToken')
    };
    const body = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.onSubmitApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.queAnsLandingPageEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
