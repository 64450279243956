Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Keep me signed in";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.errorAllFieldsAreMandatory = "Please complete all required fields";

exports.appName = "MedEd Cloud";
exports.headingWelcome = "Welcome back to";
exports.nextBtnText = "Go!";
exports.errorEmail = "Please input email address";
exports.errorInvalidEmail = "Please enter a valid email address";
exports.passwordUpperCaseValidation = "At least one capital letter";
exports.passwordLowerCaseValidation = "At least one lowercase letter";
exports.passwordNumberValidation = "At least one number";
exports.passwordLengthValidation = "At least eight characters";
exports.passwordMatchValidation = "Passwords do not match";
exports.validateEmailText = "We have sent a link to your email address. Head over there now and validate your email address so we can start to personalise your journey!";
exports.emailValidatedText = "Thanks for validating! Now, let's personalise your journey on MedEd Cloud.";
exports.btnTextLogin = "Login";
exports.btnTextResendLink = "Resend Link";
exports.btnTextPersonalise = "Start Personalising";
exports.btnForgotPassword = "Forgot Password";
exports.errorInvalidEmailOrPassword = "Invalid email or password";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
// Customizable Area End