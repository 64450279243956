import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React, { ReactNode } from "react";
import {
  CountryCode,
  isValidPhoneNumber,
  getExampleNumber,
} from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
import {
  getStorageData,
  removeStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  factsBgImg?: any;
  bgImgStyle?: any;
  factsHeading?: string;
  factsInfoText?: string;
  factsInfoText2?: string | ReactNode;
  factsIcon?: any;
  factsIcon2?: any;
  factsIconLabel?: string;
  iconMsg?: any;
  infoTextHeading?: string;
  infoText?: string;
  infoTextHeadingMargin?: any;
  colouredInfoText?: string;
  backBtn?: boolean;
  goBack?: any;
  heading1?: string;
  heading2?: string;
  heading3?: string;
  heading1style?: any;
  heading2style?: any;
  heading3style?: any;
  onclick?: any;
  errors?: any;
  popupError?: any;
  popupError2?: any;
  popupError3?: any;
  warning?: any;
  warnings?: any;
  warning2?: any;
  bgImgPosition?: any;
  bgImgWidth?: any;
  bgImgLeftPosition?: any;
  bgImgTopPosition?: any;
  bottomError?: any;
  validateEmail?: boolean;
  emailValidated?: boolean;
  form?: any;
  children?: ReactNode;
  buttonText?: string;
  btnDisabled?: boolean;
  terms?: ReactNode;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordCache: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;

  phoneCode: string | null;
  isValidEmail: boolean | null;
  hasUppercase: boolean | null;
  hasLowerCase: boolean | null;
  hasNumber: boolean | null;
  hasEightChar: boolean | null;
  hasSpecialChar: boolean | null;
  error: string | null;
  errors: any;
  firstNameError: string | null;
  lastNameError: string | null;
  emailError: string | null;
  termsError: string | null;
  warnings: any;
  warning: string | null;
  isChecked: boolean;
  passwordVisible: boolean;
  repasswordVisible: boolean;
  passwordsMatch: boolean;
  country: string;
  stateData: any;
  state: string;
  selectedState: any;
  stateId: string | number;
  countryCode: CountryCode;
  isUSA: boolean;
  name: string;
  countryData: any;
  flag: any;
  phonenumber: null | string;
  phonenumberFormat: any;
  countrySelected: any;
  validationVisible?: boolean;
  isValidPhone: boolean | null | undefined;
  errorPhone: string | null;
  signupData: any;
  passwordData: any;
  locationData: any;
  termsData: any;
  passwordTempData: string | null;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  textReg: RegExp;
  phoneReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  signupApiCallId: string = "";
  termsAndConditionsApiCallId: string = "";
  emailVerificationApiCallId: string = "";
  resendEmailApiCallId: string = "";
  countriesApiCallId: string = "";
  statesApiCallId: string = "";
  emailUniqnessCallId: string = "";

  imgPasswordVisible: boolean;
  imgPasswordInVisible: boolean;

  labelHeader: string;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  factsHeadingHome: string = configJSON.factsHeadingHome;
  factsInfoTextHome: string = configJSON.factsInfoTextHome;
  factsInfoText2Home: string = configJSON.factsInfoText2Home;
  factsHeadingPassword: string = configJSON.factsHeadingPassword;
  factsInfoTextPassword: string = configJSON.factsInfoTextPassword;
  factsInfoText2Password: string = configJSON.factsInfoText2Password;
  factsHeadingCountry: string = configJSON.factsHeadingCountry;
  factsInfoTextCountry: string = configJSON.factsInfoTextCountry;
  factsInfoText2Country: string = configJSON.factsInfoText2Country;
  heading1Password: string = configJSON.heading1Password;
  heading3Password: string = configJSON.heading3Password;
  heading1Country: string = configJSON.heading1Country;
  heading3Country: string = configJSON.heading3Country;
  heading1ValidateEmail: string = configJSON.heading1ValidateEmail;
  heading3ValidateEmail: string = configJSON.heading3ValidateEmail;
  infoTextHeading: string = configJSON.infoTextHeading;
  infoText: string = configJSON.infoText;
  appName2: string = configJSON.appName2;
  heading1EmailValidated: string = configJSON.heading1EmailValidated;
  heading3EmailValidated: string = configJSON.heading3EmailValidated;
  infoTextHeadingEmailValidated: string =
    configJSON.infoTextHeadingEmailValidated;
  emailValidatedText1: string = configJSON.emailValidatedText1;
  emailValidatedText2: string = configJSON.emailValidatedText2;
  emailValidatedBoldText1: string = configJSON.emailValidatedBoldText1;
  emailValidatedBoldText2: string = configJSON.emailValidatedBoldText2;

  appName: string;
  headingWelcome: string;
  welcomeText: string;
  homeBtnText: string;
  headingSignUpText: string;
  placeHolderEmail: string;
  placeHolderFirstName: string;
  placeHolderLastName: string;
  terms: string;
  nextBtnText: string;
  errorAllFieldsAreMandatory: string;
  errorFirstName: string;
  errorFirstNameLength: string;
  errorLastName: string;
  errorLastNameLength: string;
  errorEmail: string;
  errorInvalidEmail: string;
  errorTermsAndConditions: string;
  passwordUpperCaseValidation: string;
  passwordLowerCaseValidation: string;
  passwordNumberValidation: string;
  passwordLengthValidation: string;
  passwordSpecialCharValidation: string;
  passwordMatchValidation: string;
  passwordRequirement: string;
  errorPasswordLength: string;
  labelCountry: string;
  placeholderCountry: string;
  labelState: string;
  placeholderState: string;
  labelCountryCode: string;
  labelPhone: string;
  errorStateSelected: string;
  errorCountrySelected: string;
  errorCountryCode: string;
  errorInvalidPhone: string;
  errorPhonenumber: string;
  validateEmailText: string;
  emailValidatedText: string;
  btnTextLogin: string;
  btnTextResendLink: string;
  btnTextPersonalise: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordCache: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      phonenumberFormat: "",
      isValidEmail: null,
      hasUppercase: null,
      hasLowerCase: null,
      hasNumber: null,
      hasEightChar: null,
      hasSpecialChar: null,
      passwordsMatch: false,
      error: null,
      errors: [],
      isChecked: false,
      passwordVisible: false,
      repasswordVisible: false,
      isUSA: false,
      name: "Joshua",
      countryData: [],
      countrySelected: undefined,
      country: "",
      state: "",
      stateData: [],
      selectedState: "",
      stateId: 1,
      flag: null,
      countryCode: "US",
      phonenumber: "",
      phoneCode: "",
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      termsError: null,
      warning: null,
      warnings: [],
      validationVisible: true,
      isValidPhone: undefined,
      errorPhone: null,
      signupData: {},
      passwordData: {},
      locationData: {},
      termsData: [],
      passwordTempData: null,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp(
      /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,}(?:\.[A-Z]{2,})?$/i
    );
    this.textReg = new RegExp(/^[a-zA-Z\s]+$/);
    this.phoneReg = new RegExp(/^[0-9()\-\s]+$/);

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;

    this.appName = configJSON.appName;
    this.headingWelcome = configJSON.headingWelcome;
    this.welcomeText = configJSON.welcomeText;
    this.homeBtnText = configJSON.homeBtnText;
    this.headingSignUpText = configJSON.headingSignUpText;
    this.placeHolderEmail = configJSON.placeHolderEmail;
    this.placeHolderFirstName = configJSON.placeHolderFirstName;
    this.placeHolderLastName = configJSON.placeHolderLastName;
    this.terms = configJSON.terms;
    this.nextBtnText = configJSON.nextBtnText;
    this.errorAllFieldsAreMandatory = configJSON.errorAllFieldsAreMandatory;
    this.errorFirstName = configJSON.errorFirstName;
    this.errorFirstNameLength = configJSON.errorFirstNameLength;
    this.errorLastName = configJSON.errorLastName;
    this.errorLastNameLength = configJSON.errorLastNameLength;
    this.errorEmail = configJSON.errorEmail;
    this.errorInvalidEmail = configJSON.errorInvalidEmail;
    this.passwordUpperCaseValidation = configJSON.passwordUpperCaseValidation;
    this.passwordLowerCaseValidation = configJSON.passwordLowerCaseValidation;
    this.passwordNumberValidation = configJSON.passwordNumberValidation;
    this.passwordLengthValidation = configJSON.passwordLengthValidation;
    this.passwordSpecialCharValidation =
      configJSON.passwordSpecialCharValidation;
    this.passwordMatchValidation = configJSON.passwordMatchValidation;
    this.passwordRequirement = configJSON.passwordRequirement;
    this.errorPasswordLength = configJSON.errorPasswordLength;
    this.labelCountry = configJSON.labelCountry;
    this.placeholderCountry = configJSON.placeholderCountry;
    this.labelState = configJSON.labelState;
    this.placeholderState = configJSON.placeholderState;
    this.labelCountryCode = configJSON.labelCountryCode;
    this.labelPhone = configJSON.labelPhone;
    this.errorStateSelected = configJSON.errorStateSelected;
    this.errorCountrySelected = configJSON.errorCountrySelected;
    this.errorCountryCode = configJSON.errorCountryCode;
    this.errorInvalidPhone = configJSON.errorInvalidPhone;
    this.errorPhonenumber = configJSON.errorPhonenumber;
    this.validateEmailText = configJSON.validateEmailText;
    this.emailValidatedText = configJSON.emailValidatedText;
    this.btnTextLogin = configJSON.btnTextLogin;
    this.btnTextResendLink = configJSON.btnTextResendLink;
    this.btnTextPersonalise = configJSON.btnTextPersonalise;
    this.errorTermsAndConditions = configJSON.errorTermsAndConditions;
    // Customizable Area End
  }

  //Customizable Area Start
  
  async componentDidMount() {
    await super.componentDidMount();
    await this.getSignupTempData();
    await this.getLocationTempData();
    await this.getPasswordTempData();
    await this.getUserData();
    
    this.getTermsAndConditions();
    
    const loaderPage = window.location.pathname;
    if (loaderPage === "/CreateCountry") {
      this.getCountries();
      this.getStates();
    }
  }

  //Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      switch (apiRequestCallId) {
        case this.termsAndConditionsApiCallId:
          this.handleTermsAndConditionsApiResponse(responseJson);
          break;
        case this.countriesApiCallId:
          this.handleCountriesResponse(responseJson);
          break;
        case this.statesApiCallId:
          this.handleStatesResponse(responseJson);
          break;
        case this.resendEmailApiCallId:
          this.handleResendLinkResponse(responseJson);
          break;
        case this.signupApiCallId:
          this.handleSignupResponse(responseJson);
          break;
        case this.emailUniqnessCallId:
          this.handleEmailUniqnessResponse(responseJson, errorReponse);
          break;
      }

      if (apiRequestCallId && responseJson) {
        this.handleCreateAccountResponse(apiRequestCallId, responseJson, errorReponse);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  handleCreateAccountResponse = (
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: any
  ) => {
    if (apiRequestCallId === this.validationApiCallId) {
      this.arrayholder = responseJson.data;

      if (this.arrayholder && this.arrayholder.length !== 0) {
        let regexData = this.arrayholder[0];

        if (regexData.password_validation_regexp) {
          this.passwordReg = new RegExp(regexData.password_validation_regexp);
        }

        if (regexData.password_validation_rules) {
          this.setState({
            passwordHelperText: regexData.password_validation_rules,
          });
        }

        if (regexData.email_validation_regexp) {
          this.emailReg = new RegExp(regexData.email_validation_regexp);
        }
      }
    } else if (apiRequestCallId === this.createAccountApiCallId) {
      if (!responseJson.errors) {
        const message: Message = new Message(
          getName(MessageEnum.AccoutResgistrationSuccess)
        );

        message.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );

        this.send(message);
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
  };
  
  getTermsAndConditions = () => {
    const header = {
      "Content-Type": configJSON.contentType,
    };

    const terms = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.termsAndConditionsApiCallId = terms.messageId;

    terms.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsAndConditionsEndpoint
    );

    terms.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    terms.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(terms.id, terms);
  };

  handleTermsAndConditionsApiResponse = (response: any) => {
    if (response?.data) {
      this.setState({ termsData: response.data });
    }
  };

  getCountries = () => {
    const header = {
      "Content-Type": configJSON.contentType,
    };

    const countries = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.countriesApiCallId = countries.messageId;

    countries.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countriesEndpoint
    );

    countries.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    countries.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(countries.id, countries);
  };

  checkEmailUniqness = () => {
    const header = {
      "Content-Type": configJSON.contentType,
    };

    const emailUniqness = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailUniqnessCallId = emailUniqness.messageId;

    emailUniqness.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.checkEmailUniqnessEndpoint}?email=${this.state.email}`
    );

    emailUniqness.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    emailUniqness.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(emailUniqness.id, emailUniqness);
  };

  handleCountriesResponse = (response: any) => {
    if (response?.data) {
      this.setState({ countryData: response.data });
      this.selectCountry(this.state.countrySelected ?? response.data.find((value: any) => value.id === "US"), false);
    } else {
      this.setState({
        error: "Error fetching countries, please try again later",
      });
    }
  };

  handleEmailUniqnessResponse = (response: any, error: any) => {
    if (response.errors || error) {
      this.setState({ error: response.errors ?? error, warning: null });
    } else {
      this.props.navigation.navigate("CreatePassword");
    }
  };

  getStates = () => {
    const header = {
      "Content-Type": configJSON.contentType,
    };
    const states = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
    this.statesApiCallId = states.messageId;
    
    states.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.statesEndpoint
    );
    
    states.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    states.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    
    runEngine.sendMessage(states.id, states);
  };

  handleStatesResponse = (response: any) => {
    if (response) {
      this.setState({ stateData: response });
    }
  };

  resendEmailVerfLink = () => {
    const header = {
      "Content-Type": configJSON.contentType,
    };
    
    const resendEmailLink = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.resendEmailApiCallId = resendEmailLink.messageId;
    
    resendEmailLink.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendEmailApiEndpoint + this.state.email
    );
    
    resendEmailLink.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    resendEmailLink.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    
    runEngine.sendMessage(resendEmailLink.id, resendEmailLink);
  };

  doSignup = async () => {
    const header = {
      "Content-Type": configJSON.contentType,
    };
    let signupData = await getStorageData("signupData");
    let firstName, lastName, email, isChecked;
  
    if (signupData) {
      const { firstnameTemp, lastnameTemp, emailTemp, checkedTemp } = JSON.parse(signupData);
  
      firstName = firstnameTemp;
      lastName = lastnameTemp;
      email = emailTemp;
      isChecked = checkedTemp;
  

      this.setState({
        firstName: firstName,
        lastName: lastName,
        email: email,
        isChecked: isChecked,
        name: firstName,
      });
    } else {
     
      firstName = this.state.firstName;
      lastName = this.state.lastName;
      email = this.state.email;
      isChecked = this.state.isChecked;
    }
  
    const body = {
      data: {
        type: "email_account",
        attributes: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: this.state.password,
          country: this.state.country,
          full_phone_number: `${this.state.countrySelected?.attributes?.country_code}${this.state.phone}`,
          state_id: this.state.state,
          terms_and_conditions_accepted: isChecked,
        },
      },
    };
  

    const signup = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.signupApiCallId = signup.messageId;
  
    signup.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signupEndpoint
    );
  
    signup.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    signup.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    signup.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
  
    runEngine.sendMessage(signup.id, signup);
  };
  

  handleSignupResponse = async (response: any) => {
    if (response?.data) {
      this.props.navigation.navigate("ValidateEmail");
      
      setStorageData("signupToken", response.meta.token);
      setStorageData('userData', JSON.stringify(response.data.attributes))

      removeStorageData('locationData');
      removeStorageData("signupData");
    } else if (response?.errors) {
      this.setState({
        error: `${response.errors[0]}${response.errors.length > 1 ? ` and ${response.errors[1]}` : ""}`,
      });
    } else {
      this.setState({ error: "An error occurred, please try again later" });
    }
  };


  handleResendLinkResponse = (response: any) => {
    if (response?.message) {
      this.setState({
        warning: response.message,
      });
    } else {
      this.setState({ error: "An error occurred, please try again later" });
    }
  };

  goToPrivacyPolicy() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    this.send(message);
  }

  showHidePassword = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  showHideRepassword = () => {
    this.setState({ repasswordVisible: !this.state.repasswordVisible });
  };

  goToTermsAndCondition() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  getSignupTempData = async () => {
    let signupData = await getStorageData("signupData");

    if (signupData) {
      const { firstnameTemp, lastnameTemp, emailTemp, checkedTemp } = JSON.parse(signupData);

      this.setState({
        firstName: firstnameTemp,
        lastName: lastnameTemp,
        email: emailTemp,
        isChecked: checkedTemp,
        name: firstnameTemp,
      });
    }
  };

  isStringNullOrBlank(value: string) {
    return value === null || value.length === 0;
  }


  getUserData = async () => {
    let userData = await getStorageData('userData');

    if (userData) {
      const { first_name: firstName, last_name: lastName, email } = JSON.parse(userData);

      this.setState({
        firstName,
        lastName,
        email
      })
    }
  }

  getPasswordTempData = async () => {
    const password = await getStorageData("password");
    this.setState({ password });
  };

  getLocationTempData = async () => {
    let locationData = await getStorageData("locationData");
    
    if (locationData) {
      const {
        countryTemp,
        countrySelectedTemp,
        selectedStateTemp,
        phoneTemp,
        phoneCodeTemp,
        isUSATemp,
        flagTemp,
      } = JSON.parse(locationData);
      
      this.setState(
        {
          country: countryTemp,
          countrySelected: countrySelectedTemp,
          state: selectedStateTemp,
          phone: phoneTemp,
          phoneCode: phoneCodeTemp,
          isUSA: isUSATemp,
          flag: flagTemp,
        },
        () => {
          !!this.state.phone && this.validatePhone();
        }
      );
    }
  };

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  goBack = () => {
    this.props.navigation.goBack();
  };

  goToTermsAndConditionsPage = () => {
    this.saveUserData();
    this.props.navigation.navigate("TermsAndConditions");
  };

  handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const firstName = event.target.value;
    this.setState({ firstName, error: null, warning: null });
  };

  handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lastName = event.target.value;
    this.setState({ lastName, error: null, warning: null });
  };

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value.trim();
    this.setState({ email, error: null, warning: null });
  };

  handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isChecked: event.target.checked, termsError: null });
  };

  handleNameTrim = () => {
    const firstName = this.state.firstName.trim();
    const lastName = this.state.lastName.trim();

    this.setState({ firstName, lastName }, () => {
      this.handleSignup();
    });
  };

  handleSignup = async () => {
    if (
      !this.state.firstName &&
      !this.state.lastName &&
      !this.state.email &&
      !this.state.isChecked
    ) {
      this.setState({
        warning: this.errorAllFieldsAreMandatory,
        error: null
      });
      return false;
    }

    if (!this.state.firstName || !this.state.lastName || !this.state.email) {
      this.setState({ warning: this.errorAllFieldsAreMandatory, error: null });
      return false;
    }

    if (this.state.firstName.length < 2) {
      this.setState({ warning: this.errorFirstNameLength, error: null });
      return false;
    }

    if (this.state.lastName.length < 2) {
      this.setState({ warning: this.errorLastNameLength, error: null });
      return false;
    }

    if (!this.textReg.test(this.state.firstName)) {
      this.setState({ error: this.errorFirstName, warning: null });
      return false;
    }

    if (!this.textReg.test(this.state.lastName)) {
      this.setState({ error: this.errorLastName, warning: null });
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.setState({ error: this.errorInvalidEmail, warning: null });
      return false;
    }

    if (!this.state.isChecked) {
      this.setState({ warning: this.errorTermsAndConditions, error: null });
      return false;
    }

    this.checkEmailUniqness();
    this.saveUserData();
  };

  async saveUserData(){
    let signupData = {
      firstnameTemp: this.state.firstName,
      lastnameTemp: this.state.lastName,
      emailTemp: this.state.email,
      checkedTemp: this.state.isChecked,
    };

    await setStorageData("signupData", JSON.stringify(signupData));
  }

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value.trim();

    this.setState(
      { password, passwordCache: password, validationVisible: true, error: null, warning: null },
      () => {
        if (password.length === 16) {
          this.setState({ warning: this.errorPasswordLength });
        }
      }
    );
    
    this.validatePassword(password);
  };

  handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const reTypePassword = event.target.value.trim();
    this.setState({ reTypePassword, error: null, warning: null });
  };

  validatePassword = (passwordValue: string) => {
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialCharRegex = /[^a-zA-Z0-9]/;
    const numberRegex = /\d/;
    const lengthRegex = /^.{8,}$/;

    this.setState({
      hasUppercase: uppercaseRegex.test(passwordValue),
      hasLowerCase: lowercaseRegex.test(passwordValue),
      hasSpecialChar: specialCharRegex.test(passwordValue),
      hasNumber: numberRegex.test(passwordValue),
      hasEightChar: lengthRegex.test(passwordValue),
    });
  };

  createPassword = async () => {
    this.setState({ validationVisible: false });
    
    if (!this.state.password || !this.state.reTypePassword) {
      this.setState({ warning: this.errorAllFieldsAreMandatory });
      return false;
    }

    if (
      !this.state.hasEightChar ||
      !this.state.hasNumber ||
      !this.state.hasUppercase ||
      !this.state.hasLowerCase ||
      !this.state.hasSpecialChar
    ) {
      this.setState({ error: this.passwordRequirement });
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.setState({
        warning: this.passwordMatchValidation,
        passwordsMatch: false,
      });
      return false;
    }

    if (this.state.password === this.state.reTypePassword) {
      this.setState({ passwordsMatch: true, error: null }, async () => {
        if (
          this.state.hasEightChar &&
          this.state.hasNumber &&
          this.state.hasUppercase &&
          this.state.hasLowerCase &&
          this.state.hasSpecialChar &&
          this.state.passwordsMatch
        ) {
          this.props.navigation.navigate("CreateCountry");
          await setStorageData("password", this.state.password);
        }
      });
    }
    return true;
  };

  handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = this.state.countryData?.find(
      (country: any) => country.attributes?.name === event.target.value
    );

    this.selectCountry(selectedCountry);
  };

  selectCountry(selectedCountry: any, reset: boolean = true) {
    const placeholder = getExampleNumber(selectedCountry?.id, examples);
    
    this.setState(
      {
        countrySelected: selectedCountry,
        country: selectedCountry?.attributes?.name,
        countryCode: selectedCountry?.id,
        phoneCode: selectedCountry?.attributes?.country_code,
        flag: selectedCountry?.attributes?.emoji_flag,
        phonenumberFormat: placeholder?.formatNational(),
        isUSA: selectedCountry?.id === "US",
        state: reset ? "" : this.state.state,
        phone: reset ? "" : this.state.phone,
        isValidPhone: reset ? null : this.state.isValidPhone,
        errorPhone: reset ? null : this.state.errorPhone, 
        error: null,
        warning: null
      },
      () => {
        this.saveLocationCache();
      }
    );
  }

  handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      state: event.target.value,
      error: null,
      warning: null,
      errorPhone: null,
    }, () => {
      this.saveLocationCache();
    });
  };

  handlePhoneChange = (event: any) => {
    const phone = event.target.value.trim();

    this.setState({
      phone,
      error: null,
      isValidPhone: null,
      warning: null,
      errorPhone: null,
    }, () => {
      this.saveLocationCache();
    });
  };

  validatePhoneNumber = (
    phoneNumber: string,
    countryCode: CountryCode
  ): boolean => {
    let parsedPhonenumber = this.state.phoneCode + phoneNumber;
    return isValidPhoneNumber(parsedPhonenumber, countryCode);
  };

  createCountry = async () => {
    if (!this.state.country && !this.state.phoneCode && !this.state.phone) {
      this.setState({ warning: this.errorAllFieldsAreMandatory });
      return false;
    }
    
    if (!this.state.state && this.state.isUSA) {
      this.setState({ error: this.errorStateSelected });
      return false;
    }
    
    if (!this.state.phone) {
      this.setState({ warning: this.errorPhonenumber });
      return false;
    }
    
    if (!this.validatePhone()) {
      return false;
    }

    this.saveLocationCache();
    this.doSignup();

    return true;
  };

  async saveLocationCache(){
    let locationData = {
      countryTemp: this.state.country,
      countrySelectedTemp: this.state.countrySelected,
      selectedStateTemp: this.state.state,
      phoneTemp: this.state.phone,
      phoneCodeTemp: this.state.phoneCode,
      isUSATemp: this.state.isUSA,
      flagTemp: this.state.flag,
    };
    
    await setStorageData("locationData", JSON.stringify(locationData));
  }

  gotToPersonalisePage = () => {
  const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "MyRole"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      !this.state.isChecked
    ) {
      this.setState({ error: this.errorAllFieldsAreMandatory });
      return false;
    }

    if (!this.state.firstName) {
      this.setState({ error: this.errorFirstName });
      return false;
    }

    if (!this.state.lastName) {
      this.setState({ error: this.errorLastName });
      return false;
    }

    if (!this.state.email) {
      this.setState({ error: this.errorInvalidEmail });
      return false;
    }

    let phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone,
    };

    const httpBodyData = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: httpBodyData,
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state.enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this.txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      
      this.txtInputPasswordProps.secureTextEntry = !this.state.enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnSignUpProps = {
    onPress: () => this.createAccount(),
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition(),
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address",
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  get phoneStateColor() {
    if (!this.state.phone) {
      return "inherit"
    }

    if (this.state.isValidPhone === null) {
      return "rgba(254, 243, 199, 1)";
    }

    if (this.state.isValidPhone) {
      return "rgba(114, 239, 221, 1)";
    } else {
      return "rgba(248, 113, 113, 1)";
    }
  }

  validatePhone(){
    if (this.state.phone && (!this.phoneReg.test(this.state.phone) || !this.validatePhoneNumber(this.state.phone, this.state.countrySelected.id))) {
      this.setState({ errorPhone: this.errorInvalidPhone, isValidPhone: false });
      return false;
    }

    this.setState({errorPhone:'', isValidPhone: !!this.state.phone ? true : null});

    return true;
  }

  // Customizable Area End
}
