import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: string;
  timeSlots: object[];
  serviceProviderId: string;
  serviceProviderSchedule: any;
  details: any;
  token: any;

  next: boolean;
  timeZone: string;
  timeFrame: string;
  selectedMonth: string;
  selectedYear: string;
  weeklyActivityCounts: any;
  upcomingMeetingsData: any;
  tomorrowEventsData: any;
  tasksCategoriesData: any;
  myTasksData: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  
  // Customizable Area Start
  serviceProviderDetailApiId:any;
  serviceProviderScheduleApiId:any;

  upcomingMeetingsDataFetchApiCallId:any;
  weeklyActivityCountsFetchApiCallId:any;
  tomorrowEventsDataFetchApiCallId:any;
  tasksCategoriesDataFetchApiCallId:any;
  myTasksDataFetchApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage)
    ];

    this.state = {
      selectedTime: 0,
      selectedDate: moment().format("YYYY-MM-DD"),
      timeSlots: [],
      serviceProviderId: "",
      serviceProviderSchedule: "",
      details: {},
      token: null,

      next: true,
      timeFrame: "tomorrow",
      timeZone: "Asia/Kolkata",
      selectedMonth: "July",
      selectedYear: "2024",
      weeklyActivityCounts: [],
      upcomingMeetingsData: [],
      tomorrowEventsData: [],
      tasksCategoriesData: [],
      myTasksData: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(responseJson && responseJson.errors) {

        this.setState({serviceProviderSchedule: []});

        if ( typeof responseJson.errors === 'string' ) {
          this.showAlert("", responseJson.errors)
        } else {
          this.parseApiErrorResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
      else if (apiRequestCallId != null) {
        this.setApiResponse(apiRequestCallId, responseJson)
      }
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.onUpcomingMeetingsFetch()
    await this.onWeeklyActivityCountsFetch()
    await this.onTomorrowEventsFetch()
    await this.onTasksCategoriesFetch()
    await this.onMyTasksFetch()

    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === true) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  setApiResponse = (apiRequestCallId: any, responseJson: any) => {

      if (apiRequestCallId === this.serviceProviderScheduleApiId) {
          console.log("Service Provider Schedule API Response", responseJson, responseJson.data)
          this.setState({
              serviceProviderSchedule: responseJson && responseJson.data ? responseJson.data : ''
          });
      }
      if (apiRequestCallId === this.serviceProviderDetailApiId) {
          console.log("Service Provider Detail API Response", responseJson, responseJson.data)
          let msg = new Message(getName(MessageEnum.NavigationCalendarMessage));
          msg.addData(
              getName(MessageEnum.CalendarProviderDetailsResponseMessage),
              responseJson
          );
          this.send(msg);
          this.unsubscribeMessages();
      }
      if (apiRequestCallId === this.upcomingMeetingsDataFetchApiCallId) {
          console.log("Upcoming Meetings API Response", responseJson, responseJson.data)
          this.setState({upcomingMeetingsData: responseJson.data})
      }
      if (apiRequestCallId === this.weeklyActivityCountsFetchApiCallId) {
          console.log("Activity API Response", responseJson, responseJson.data)
          this.setState({weeklyActivityCounts: responseJson})
      }
      if (apiRequestCallId === this.tomorrowEventsDataFetchApiCallId) {
          console.log("Tomorrow Events API Response", responseJson, responseJson.data)
          this.setState({tomorrowEventsData: responseJson})
      }
      if (apiRequestCallId === this.tasksCategoriesDataFetchApiCallId) {
          console.log("Tasks Categories API Response", responseJson, responseJson.data)
          this.setState({tasksCategoriesData: responseJson.data})
      }
      if (apiRequestCallId === this.myTasksDataFetchApiCallId) {
          console.log("My Tasks API Response", responseJson, responseJson.data)
          this.setState({myTasksData: responseJson})
      }
  };

  onSelectDate = (selectedDateStr: string) => {
    const {details} = this.state;
    this.setState({
      selectedDate: selectedDateStr,
      timeSlots: [],
      selectedTime: 0
    });
  };

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => this.onSelectDate(selectedDate)
  };

  onUpcomingMeetingsFetch = async() => {
      const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": await getStorageData('signupToken')
      };

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.upcomingMeetingsDataFetchApiCallId = requestMessage.messageId;

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.fetchUpcomingMeetingsEndPoint
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  };

  onWeeklyActivityCountsFetch = async() => {
      const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": await getStorageData('signupToken')
      };

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.weeklyActivityCountsFetchApiCallId = requestMessage.messageId;

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.fetchWeeklyActivityCountsEndPoint
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  };

  onTomorrowEventsFetch = async() => {
      const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": await getStorageData('signupToken')
      };

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.tomorrowEventsDataFetchApiCallId = requestMessage.messageId;

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.fetchTomorrowEventsEndPoint
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  };

  onTasksCategoriesFetch = async() => {
      const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": await getStorageData('signupToken')
      };

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.tasksCategoriesDataFetchApiCallId = requestMessage.messageId;

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.fetchTasksCategoriesEndPoint
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  };

  onMyTasksFetch = async() => {
      const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": await getStorageData('signupToken')
      };

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.myTasksDataFetchApiCallId = requestMessage.messageId;

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.fetchMyTasksEndPoint
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }

  unsubscribeMessages = () => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  };
  // Customizable Area End
}