// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import LoginComp from "../../signuplogin/src/LoginComp.web";
import './styles/customForm.css';

import CustomFormController, {
  Props
} from "./CustomFormController.web";
import ProgressBar from "./ProgressBar.web";
// Customizable Area End

export default class LearningVertical extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LoginComp
        navigation={undefined}
        id={""}
        setup={true}
        onhover={this.handleIconHover}
        onleave={this.handleIconLeave}
        showInfo={this.state.showInfo}
        bgImgColor={
          "linear-gradient(to bottom, #5591f500 10%, #72efdd50, #5591f5)"
        }
        heading={this.learningVerticalHeading}
        infoText1={this.learningVerticalInfoText}
        infoTextStyle={"desc-text"}
        backBtn={true}
        goBack={this.goBack}
        buttonText={"Next"}
        btnDisabled={this.state.verticalBtn}
        onclick={this.handleLearningVertical}
        customForm={
          <ProgressBar
            navigation={undefined}
            id={""}
              role={true}
              vertical={this.state.verticalStep}
              journey={false}
              institution={false}
              immediateGoal={false}
              futureGoal={false}
              step1={true}
              step2={false}
              step3={false}
              step4={false}
              step5={false}
              step6={false}
          />
        }
        formContent={
          <div className="formcontent-wrapper" data-testid="formContentWrapper">
            {this.learningVerticals.map((vertical, index) => {
              const isSelected = this.state.selectedVertical === vertical.name;
              return (
                <div
                  key={index.toString()}
                  className={`${
                    isSelected
                      ? "vertical-container selected-container"
                      : "vertical-container"
                  }`}
                  data-testid={`${vertical.name}-container`}
                >
                  <div
                    key={vertical.id}
                    className={`${
                      isSelected
                        ? "vertical-wrapper selected-wrapper"
                        : "vertical-wrapper"
                    }`}
                    data-testid={`${vertical.name}-wrapper`}
                  >
                    <span
                      className={`${
                        isSelected
                          ? "vertical-icon  selected-icon"
                          : "vertical-icon"
                      }`}
                      data-testid={`${vertical.name}-iconWrapper`}
                    >
                      <img
                        src={vertical.icon}
                        alt={`${vertical.name}-icon`}
                        data-testid={`${vertical.name}-icon`}
                      />
                    </span>
                    <label
                      htmlFor={`vertical-${index}`}
                      className={`${
                        isSelected
                          ? "vertical-label  selected-label"
                          : "vertical-label"
                      }`}
                      data-testid={`${vertical.name}-label`}
                    >
                      {vertical.name}
                      <input
                        type="radio"
                        id={`vertical-${index}`}
                        name="vertical"
                        value={vertical.name}
                        className="radioBtn"
                        onChange={(e) => this.handleVerticalChange(e)}
                        checked={isSelected}
                        data-testid={`${vertical.name}-vertical`}
                      />
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        }
      />
      // Customizable Area End
    )
  }
}
// Customizable Area End
