import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import LoginComp from "../../signuplogin/src/LoginComp.web";
import '../../signuplogin/src/styles/loginForm.css';
import { bgImg, verified_user } from "./assets";
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class NewPasswordSet extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
        <LoginComp 
          navigation={undefined} 
          id={""}
          pgIcon={verified_user}
          bgImg={bgImg}
          backBtn={false}
          goBack={this.goBack}
          heading={this.passwordSetHeading}
          infoText1={this.passwordSetText1}
          infoText2={this.passwordSetText2}
          infoText3={this.passwordSetText3}
          infoTextMt2={"30px"}
          buttonText={this.loginBtn}
          onclick={this.goToLoginPage}
          />
       // Customizable Area End
    );
  }
}


