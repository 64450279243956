Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";

exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";
// Customizable Area Start
exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
exports.resetPasswordApiEndpoint="bx_block_forgot_password/forgot_reset_password"
exports.emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,}(?:\.[A-Z]{2,})?$/i;
exports.heading = "Reset your password";
exports.infoText1 = "To get back into your account, simply enter your email address.";
exports.infoText2 = "We'll send you instructions on how to reset your password.";
exports.labelEmail = "Email address";
exports.btnNext = "Send link";
exports.errorEmptyEmail = "Field is mandatory";
exports.errorInvalidEmail = "Please enter a valid email address";
exports.errorEmailNotFound = "It seems there's no account linked to this email. Sign up to connect with us!";
exports.passwordUsed = "Looks like you've used that password before. For your account's safety, please create a different password.";
exports.linkSent = "Check your inbox"
exports.passwordResetText1 = "A fresh start is just an email away!";
exports.passwordResetText2 = "We've sent a password reset link to your inbox.";
exports.passwordResetText3 = "Click on it to set your new password and get back on track!";
exports.btnResendLink = "Resend Link";
exports.newPasswordHeading = "Create your new password";
exports.btnNewPassword = "Set new password";
exports.password = " New password";
exports.reEnterPassword = "Re-enter new password";
exports.passwordUpperCaseValidation = "1 uppercase";
exports.passwordLowerCaseValidation = "1 lowercase";
exports.passwordNumberValidation = "1 number";
exports.passwordLengthValidation = "8 characters";
exports.passwordSpecialCharValidation = "1 special character";
exports.passwordLengthError = "Please review, maximum 16 allowed characters.";
exports.passwordSetHeading = "New password set!";
exports.passwordSetText1 = "Great job! Your password has been successfully changed. ";
exports.passwordSetText2 = "Go ahead and sign in to MedEd CLOUD with your ";
exports.passwordSetText3 = "new password and pick up right where you left off.";
exports.loginBtn = "Login";

exports.passwordMatchValidation = "Looks like your passwords aren't matching. Please review!";
exports.passwordRequirement = "Your password isn't matching up with our guidelines. Try again!";
exports.errorAllFieldsAreMandatory = "Please complete all required fields";
exports.forgotPasswordEmailLinkEndPoint="bx_block_forgot_password/forgot_password"
// Customizable Area End