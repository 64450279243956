import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Paper,
  Card,
  CardContent
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import { Movie } from "./KeywordsearchController";
// Customizable Area End

import KeywordsearchController, {
  Props,
  configJSON,
} from "./KeywordsearchController";

export default class Keywordsearch extends KeywordsearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Box
            sx={styles.mainWrapper}
          >
            <Input
              data-test-id={"inputSearchText"}
              placeholder={configJSON.search}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setInputValue(event.target.value)}
            />
            <Button
              data-test-id="btnGetSearchList"
              variant="contained"
              color="primary"
              onClick={() => this.getSearchList(this.state.token)}
            >
              {configJSON.search}
            </Button>
          </Box>

          <Paper style={styles.papertheme}>
            {this.state.searchList.map((item: Movie, index: number) => {
              return (
                <Card style={styles.marginB}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {item.attributes.title}
                    </Typography>
                    <Typography variant="body2" style={styles.contentDetails}>
                      <span><span style={styles.fontBold}>{configJSON.rating}:</span> {item.attributes.rating}</span>
                      <span><span style={styles.fontBold}>{configJSON.director}:</span> {item.attributes.director}</span>
                      <span><span style={styles.fontBold}>{configJSON.producer}:</span> {item.attributes.producer}</span>
                      <span><span style={styles.fontBold}>{configJSON.duration}:</span> {item.attributes.duration}</span>
                    </Typography>
                  </CardContent>
                </Card>
              )
            })}
          </Paper>
        </Container>
        
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = {
  mainWrapper: {
    display: "flex" as const,
    flexDirection: "row" as const,
    justifyContent: "flex-end" as const,
    alignItems: "center" as const,
  },
  papertheme: {
    width: "100%",
  },
  tableHeight: {
    maxHeight: '440'
  },
  contentDetails: {
    flexDirection: 'column' as const,
    display: 'flex'
  },
  fontBold: {
    fontWeight: 'bold' as const
  },
  marginB: {
    marginBottom: '2%'
  }
}
// Customizable Area End
