export const book = require("../assets/book.png");
export const capsule = require("../assets/capsule.png");
export const chair = require("../assets/chair.png");
export const clipboard = require("../assets/clipboard.png");
export const eye = require("../assets/eye.png");
export const foot = require("../assets/foot.png");
export const gavel = require("../assets/gavel.png");
export const institution = require("../assets/institution.png");
export const microphone = require("../assets/microphone.png");
export const nurse = require("../assets/nurse.png");
export const paw = require("../assets/paw.png");
export const school = require("../assets/school.png");
export const spine = require("../assets/spine.png");
export const stethoscope = require("../assets/stethoscope.png");
export const stretch = require("../assets/stretch.png");
export const tooth = require("../assets/capsule.png");
export const trophy = require("../assets/trophy.png");
export const skipArrow = require("../assets/skipArrow.svg");
export const rocketImg = require("../assets/rocketImg.svg");
export const loaderBg = require("../assets/loaderBg.svg");
export const bgBlur = require("../assets/bgBlur.svg");
export const progressBg = require("../assets/progressBg.svg");
