import React from "react";

// Customizable Area Start
import {
  Box, Typography,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import LoginComp from "../../signuplogin/src/LoginComp.web";
import '../../signuplogin/src/styles/loginForm.css';
import { password, bgImg } from "./assets";
import { IoEyeOutline, IoEyeOffOutline, IoCloseCircleOutline, IoCheckmarkCircleOutline } from "react-icons/io5";
import { MdOutlineCircle } from "react-icons/md";
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class NewPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
  renderValidationIcon = (isValid: boolean | null) => {
    if (isValid === null) {
      return <MdOutlineCircle color={'#ffffff'} size={21} className="circleIcon" data-testid="closeIcon"/>;
    }
    return isValid ? <IoCheckmarkCircleOutline color={'#72efdd'} size={21} data-testid="checkIcon" className="checkIcon" /> : <IoCloseCircleOutline color={'#f87171'} size={21} className="closeIcon" data-testid="dashIcon"  />;
  }

  renderPasswordType = () => {
    return this.state.passwordVisible ? "text" : "password";
  }

  renderRePasswordType = () => {
    return this.state.rePasswordVisible ? "text" : "password";
  }

  renderPasswordIcon = () => {
    return this.state.passwordVisible ? <IoEyeOffOutline className="eyeIcon" data-testid="passwordInvisible" /> : <IoEyeOutline className="eyeIcon" data-testid="passwordVisible" />};

  renderRePasswordIcon = () => {
    return this.state.rePasswordVisible ? <IoEyeOffOutline className="eyeIcon" data-testid="repasswordInvisible" /> : <IoEyeOutline className="eyeIcon" data-testid="repasswordVisible" />
  }

  renderClassname = (validation: any) => {
    if (validation === null) return 'validationText' 
    return validation ? 'isValid': 'notValid';
  }

  
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <LoginComp 
          navigation={undefined} 
          id={""} 
          pgIcon={password}
          bgImg={bgImg}
          backBtn={false}
          goBack={this.goBack}
          heading={this.newPasswordHeading}
          onclick={this.resetPassword}
          buttonText={this.btnNewPassword}
          popupError={this.state.error}
          popupErrorStyle={"popupErrorStyle"}
          warning={this.state.warning}
          form={
            <Box className="loginFormContainer"> 
              <Box className="input-wrapper">
                <input 
                  type={this.renderPasswordType()}  
                  id="password" 
                  name="password"
                  value={this.state.newPassword} 
                  onChange={(e) => this.handleNewPasswordChange(e)}
                  className="input-field new-paswrd1"
                  data-testid="password"
                  maxLength={16}
                  required />
                  <label htmlFor="password" className="label-text" data-testid="labelPassword">{this.password}</label>
                  <span onClick={this.showHidePassword} className="passwordIconWrapper" data-testid="passwordVisibleWrapper">{this.renderPasswordIcon()}</span>
              </Box>

              <Box className="inputWrapper">
                <input 
                  type={this.renderRePasswordType()}  
                  id="reEnterPassword" 
                  name="reEnterPassword" 
                  value={this.state.retypeNewPassword}
                  onChange={(e) => this.handleRetypePasswordChange(e)}
                  className="input-field new-paswrd2"
                  data-testid="rePassword"
                  maxLength={16}
                  required />
                  <label className="label-text" htmlFor="reEnterPassword"  data-testid="labelRepassword" >{this.reEnterPassword}</label>

                  <span onClick={this.showHideRepassword} className="passwordIconWrapper" data-testid="repasswordVisibleWrapper">{this.renderRePasswordIcon()}</span>
              </Box>

              { this.state.isValidationVisible && 
                <Box className="validation-container">
                  <Box className="validation-wrapper" data-testid="uppercase">
                    {this.renderValidationIcon(this.state.uppercase)}&nbsp;&nbsp;<Typography component="p" className={this.renderClassname(this.state.uppercase)}>{this.passwordUpperCaseValidation}</Typography>
                  </Box>

                  <Box className="validation-wrapper" data-testid="lowercase">
                    {this.renderValidationIcon(this.state.lowercase)}&nbsp;&nbsp;<Typography component="p" className={this.renderClassname(this.state.lowercase)}>{this.passwordLowerCaseValidation}</Typography>
                  </Box>

                  <Box className="validation-wrapper">
                    {this.renderValidationIcon(this.state.specialChar)}&nbsp;&nbsp;<Typography component="p" className={this.renderClassname(this.state.specialChar)} data-testid="specialChar">{this.passwordSpecialCharValidation}</Typography>
                  </Box>

                  <Box className="validation-wrapper">
                    {this.renderValidationIcon(this.state.number)}&nbsp;&nbsp;<Typography component="p" className={this.renderClassname(this.state.number)} data-testid="number">{this.passwordNumberValidation}</Typography>
                  </Box>

                  <Box className="validation-wrapper">
                    {this.renderValidationIcon(this.state.eightChar)}&nbsp;&nbsp;<Typography component="p" className={this.renderClassname(this.state.eightChar)} data-testid="length">{this.passwordLengthValidation}</Typography>
                  </Box>
                </Box>
              }
            </Box>
          }/>
       // Customizable Area End
    );
  }
}
