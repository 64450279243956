// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import LoginComp from "../../signuplogin/src/LoginComp.web";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import './styles/customForm.css';

import CustomFormController, {
  Props, GoalSection, Goal 
} from "./CustomFormController.web";
import { trophy } from "./assets";
import ProgressBar from "./ProgressBar.web";
// Customizable Area End

export default class MyImmediateGoals extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      ...this.state,
      goalSections: this.generateGoalSections()
    };
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <LoginComp 
          navigation={undefined} 
          id={""} 
          setup={true}
          onhover={this.handleIconHover}
          onleave={this.handleIconLeave}
          showInfo={this.state.showInfo}
          bgImgColor={'linear-gradient(to bottom, #5591f500 10%, #b429f950, #5591f5)'}
          heading={this.immediateGoalsHeading}
          infoText1={this.immediateGoalsInfoText}
          infoTextStyle={"desc-text"}
          backBtn={true}
          goBack={this.goBack}
          buttonText={'Next'}
          btnDisabled={this.state.immediateGoalBtn}
          onclick={this.handleImmediateGoal}
          contentContainerStyle={"contentContainerStyle"}
          customForm={
            <ProgressBar
              navigation={undefined}
              id={""}
              role={true}
              vertical={true}
              journey={true}
              institution={true}
              immediateGoal={this.state.immediateGoalStep}
              futureGoal={false}
              step1={true}
              step2={true}
              step3={true}
              step4={true}
              step5={false}
              step6={false}
            />
          }
          formContent={
            <div className="goal-container" data-testid="goal-container">
           {this.state.goalSections.map((section: GoalSection, sectionIndex: number) => (      
            <Accordion key={sectionIndex} style={{background:'transparent',boxShadow:'none'}}>
                  <AccordionSummary
                    expandIcon={<h6 style={{color:'white'}}><ArrowDropDownIcon/></h6>}
                    aria-controls={`panel${sectionIndex}-content`}
                    id={`panel${sectionIndex}-header`}
                  >
                    <Typography style={{color:'white'}}>{section.name}</Typography>

                    <div
                    style={{
                      border: '1px solid #FFFFFF',
                      width: '600px',
                      height: '0px',
                      position: 'absolute',
                      bottom: '-3px',
                      left: '18',
                      opacity: '0.1',
                      transform: 'rotate(0deg)',
                    }}
                    ></div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      {section.goals.map((goal:Goal, index:number) => (
                        <div
                          key={index.toString()}
                          className={`${
                            this.state.checkedItems.some(
                              (item: any) => item.id === goal.id
                            )
                              ? "goal-wrapper selected-goal-wrapper"
                              : "goal-wrapper"
                          }`}
                          data-testid={`goal${goal.goal}-wrapper`}
                        >
                          <label
                            className="goal-label"
                            htmlFor={`goal-${goal.id}`}
                            data-testid={`goal-${goal.id}`}
                          >
                            <span
                              className={`${
                                this.state.checkedItems.some(
                                  (item: any) => item.id === goal.id
                                )
                                  ? "goal-icon selected-goal-icon"
                                  : "goal-icon"
                              }`}
                              data-testid="iconWrapper"
                            >
                              <img
                                src={trophy}
                                alt="trophy-icon"
                                data-testid="trophy-icon"
                              />
                            </span>
                            <input
                              type="checkbox"
                              id={`goal-${goal.id}`}
                              name={goal.id.toString()}
                              value={goal.goal}
                              className="goal-checkbox"
                              onChange={this.handleGoalChange}
                              checked={this.state.checkedItems.some(
                                (item: any) => item.id === goal.id
                              )}
                              data-testid={`goal${goal.id}-input`}
                            />{" "}
                            {goal.goal}
                          </label>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          }
        />
      // Customizable Area End
    );
  }
}
// Customizable Area End
